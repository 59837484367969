import firebase from 'firebase';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/analytics';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export const firebaseAnalytics = firebase.analytics();

export const signInWithPhoneNumber = (phoneNumber, appVerifier) => {
  return firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
    })
    .catch((err) => err);
};

export const signInWithEmail = (userEmail, userLink) => {
  return firebase
    .auth()
    .sendSignInLinkToEmail(userEmail, userLink)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
    })
    .catch((err) => {
      // console.log(err)
    });
};

export const signInWithEmailLink = (userEmail, userLink) => {
  return firebase
    .auth()
    .signInWithEmailLink(userEmail, userLink)
    .then((result) => {
      // console.log('signInWithEmailLink', result);
      localStorage.setItem('_uid', result.user.uid);

      result.user.getIdToken().then((token) => {
        sessionStorage.setItem('pay-mobile-token', token);
      });
      sessionStorage.setItem('pay-mobile-refreshToken', result.user.refreshToken);

    })
    .catch((err) => {
      // console.log(err)
    });
};

export const onAuthStateChanged = (callback) =>
  firebase.auth().onAuthStateChanged(callback);

export const signOut = () => firebase.auth().signOut();
