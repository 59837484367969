import { Box, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ButtonApp from '../../Components/ButtonApp';
import LoadingStep from '../../Components/LoadingStep';
import ProgressHeader from '../../Components/ProgressHeader';
import { getConfig } from '../../config/config';
import { Renderer } from '../../config/Renderer';
import { Context } from '../../Context';
import useGetHelpData from '../../hooks/useGetHelpData';
import useGetMe from '../../hooks/useGetMe';
import sendOTP from '../../services/sendOTP';
import sendEmailOTP from '../../services/sendEmailOTP';
import signContract from '../../services/signContract';
import {useCountdownTimer} from "use-countdown-timer";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: 'App-bold',
    textDecoration: 'underline',
    fontSize: 12,
    display: 'inline',
  },
}));

const Contract = () => {
  const classes = useStyles();
  const {me, loadingMe} = useGetMe();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState('idle');
  const [codeSent, setCodeSent] = useState(false);
  const history = useHistory();
  const helpData = useGetHelpData(
      me?.user?.profile,
      me?.user?.profile === 'Persona Juridica'
          ? me?.steps?.step_10?.name
          : me?.steps?.step_9?.name
  );

  const {setDesktopImg} = useContext(Context);

  const [timer] = useState(1000 * 60)
  const [showCountdown, setShowCountdown] = useState(true)
  const { countdown, start, reset, pause, isRunning } = useCountdownTimer({
    timer,
    autostart: true,
    onExpire: () => {
      setActions((actions) => [...actions, 'Expire Callback']);
      console.log('onExpire');
      setShowCountdown(false);
    },
    onReset: () => {
      setActions((actions) => [...actions, 'Reset Callback']);
      console.log('onReset');
    },
  })
  const [actions, setActions] = useState([])

  const logAction = (message: string, action: () => void) => {
    setActions((actions) => [...actions, message])
    action()
  }

  useEffect(() => {
    if (helpData?.desktop_img) {
      setDesktopImg(helpData?.desktop_img);
    }
  }, [helpData?.desktop_img]);

  useEffect(() => {
    const onSendOtp = () => {
      if (codeSent) {
        return;
      }
      setCodeSent(true);
      setLoading('pending');
      sendOTP()
          .then((res) => {
            toast.success(`código de verificación enviado exitosamente`);
            setLoading('resolved');
          })
          .catch((err) => {
            toast.error(err, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading('rejected');
          });
    };
    if (me) {
      onSendOtp();
    }
  }, [me, codeSent]);

  const config = useMemo(() => {
    if (me) {
      return getConfig({
        state,
        setState,
        componentsFromServer:
            me?.user?.profile === 'Persona Juridica'
                ? me?.steps?.step_10?.body
                : me?.steps?.step_9?.body,
      });
    }
  }, [state, me]);

  const onSignContract = () => {
    const token = {token: state['field_85']};
    setLoading('pending');
    signContract(token)
        .then((res) => {
          // console.log('res', res);
          if (res.status) {
            history.push('/submitted');
          } else {
            toast.error(res.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setLoading('resolved');
        })
        .catch((err) => {
          toast.error(err, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading('rejected');
        });
  };

  const onEmailOtp = () => {
      sendEmailOTP()
          .then((res) => {
            toast.success(`código de verificación enviado al correo exitosamente`);
            setLoading('resolved');
          })
          .catch((err) => {
            toast.error(err, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading('rejected');
          });
  }

  if (loadingMe) {
    return <LoadingStep />;
  }

  return (
    <Box
      padding="25px"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      style={{ overflowY: 'auto' }}
    >
      <ProgressHeader
        title={
          me?.user?.profile === 'Persona Juridica'
            ? me?.steps?.step_10?.title
            : me?.steps?.step_9?.title
        }
        progress={
          me?.user?.profile === 'Persona Juridica'
            ? me?.steps?.step_10?.title
            : me?.steps?.step_9?.progress
        }
        onBack={() => history.push('/bank_account')}
        steps={me?.steps}
      />
      <Box flexGrow={1}>
        <Box marginTop="30px">
          <Typography style={{ fontFamily: 'App-bold', textAlign: 'center' }}>
            Firma de contrato
          </Typography>
          <Typography style={{ fontSize: 12, marginTop: 12 }} align="center">
            Te hemos enviado un código de verificación al celular{' '}
            <span style={{ fontFamily: 'App-semi-bold' }}>
              {/* {me?.user?.phone?.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})/g,
                '$1 $2 $3 $4'
              )} */}
              {me?.user?.phone?.replace(/(\d{3})(\d{3})(\d{4})/g, '$1 $2 $3')}
            </span>{' '}
            {/*y al correo{' '}
            <span style={{ fontFamily: 'App-semi-bold' }}>
              {me?.user?.email}
            </span>*/}
          </Typography>

          <Renderer config={config} />

          <div>
            {showCountdown ?
                <Typography
                    style={{
                      fontFamily: 'App-font-medium',
                      fontSize: 13,
                      marginBottom: '13px',
                      marginTop: '25px',
                      color: '#6F7782',
                    }}
                    align="center"
                > Se habilitará un nuevo codigo en: {countdown/1000} </Typography>
                :
                <div>

                  <Typography style={{ marginTop: 25, fontFamily: 'App-font-medium',
                    fontSize: 13,
                    marginBottom: '13px',
                    color: '#6F7782', }} align="center">
                    ¿No te llego el código de verificación al número celular? <br/>
                    <a href="#" onClick={onEmailOtp} rel="noreferrer" >
                      <Typography className={classes.text}
                                  style={{
                                    fontFamily: 'App-semi-bold',
                                    fontSize: 12,
                                    color: '#00e39c',
                                    cursor: 'pointer',
                                  }}>Enviar código al Correo Electrónico</Typography>
                    </a>
                  </Typography>

                </div>
            }

          </div>









          <Typography style={{ fontSize: 12, marginTop: 25 }} align="center">
            Al introducir el código de verificación y dar click en “Firmar” se
            aceptan los términos y condiciones indicados{' '}
            <a href={helpData?.terms} target="_blank" rel="noreferrer">
              <Typography className={classes.text}>aquí.</Typography>
            </a>
          </Typography>
          {/* <a href={helpData?.sign_contract} target="_blank" rel="noreferrer">
            <Typography
              style={{
                fontSize: 14,
                fontFamily: 'App-semi-bold',
                color: '#00e39c',
                cursor: 'pointer',
                marginTop: 25,
              }}
              align="center"
            >
              Ver contrato
            </Typography>
          </a> */}
        </Box>
      </Box>



      <ButtonApp
        text="Firmar"
        loading={loading === 'pending'}
        disabled={state['field_85'] ? state['field_85'].length !== 6 : true}
        onClick={onSignContract}
      />
    </Box>
  );
};

export default Contract;
