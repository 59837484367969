import React from 'react';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { Backdrop, Box, Fade, makeStyles, Modal } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3, 4, 3),
    borderRadius: 8,
    minWidth: 300,
    maxWidth: 350,
    outline: 0,
  },
}));
const ModalApp = ({ open, handleClose, children, type }) => {
  const classes = useStyles();
  const renderType = () => {
    if (type === 'warning') {
      return (
        <Box>
          <Box
            display="flex"
            width="75px"
            height="75px"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            margin="auto"
            border="1px solid #00e39c80"
          >
            <NotificationImportantIcon htmlColor="#00e39c" fontSize="large" />
          </Box>
        </Box>
      );
    }

    return null;
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {renderType()}
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalApp;
