import { useState, useEffect } from 'react';

const useInput = (initialValue = '', submitting) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!value && submitting) {
      setError('Este campo es requerido');
    }
  }, [submitting, value]);

  const onChange = (e) => {
    setValue(e.target.value);
    if (value !== e.target.value) {
      setError('');
    }
  };

  return {
    value,
    error,
    onChange,
    setError,
    setValue,
  };
};

export default useInput;
