import { appEndpoint } from '../utils/axios';

const registerValidation = async (
  profile,
  first_name,
  second_name,
  first_last_name,
  second_last_name,
  companyName,
  documentId,
  expeditionDate,
  nit,
  ciiu,
  refferCode,
  uid,
  phone,
  userEmail,
  terms,
  referral_code,
  document_type
) => {
  const signupKeys = {
    ciiu,
    phone,
    email: userEmail,
    terms,
    uid,
    profile,
    referral_code,
  };

  if (profile === 'natural') {
    console.log('natural', expeditionDate);
    signupKeys.first_name = first_name;
    signupKeys.second_name = second_name;
    signupKeys.first_last_name = first_last_name;
    signupKeys.second_last_name = second_last_name;
    signupKeys.document_id = documentId;
    signupKeys.expedition_date = expeditionDate;
    signupKeys.document_type = document_type;
  }

  if (profile === 'legal') {
    signupKeys.company_name = companyName;
    signupKeys.nit = nit;
  }

  try {
    const res = await appEndpoint().post('/register-validation', signupKeys);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export default registerValidation;
