import { Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ProgressHeader from '../../Components/ProgressHeader';
// import selfieImg from '../../assets/images/selfie.png';
// import Webcam from 'webcam-easy';
import DescriptionItem from '../../Components/DescriptionItem';
import ButtonApp from '../../Components/ButtonApp';
import Upload from '../../Components/Upload';
import uploadSelfie from '../../services/uploadSelfie';
import { toast } from 'react-toastify';
import useGetHelpData from '../../hooks/useGetHelpData';
import { useHistory } from 'react-router-dom';
import useGetMe from '../../hooks/useGetMe';
import { dataURLtoFile } from '../../utils/utils';
import LoadingStep from '../../Components/LoadingStep';
import { Context } from '../../Context';
import Webcam from 'react-webcam';

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: 36,
    fontSize: 14,
    fontFamily: 'App-semi-bold',
    marginBottom: 24,
  },

  selfieImg: {
    width: 234,
    margin: '0 auto',
    height: 152,
    objectFit: 'cover',
    display: 'flex',
  },

  description: {
    fontSize: 12,
    marginTop: 32,
    padding: '0 40px',
  },
}));

const Selfie = () => {
  const classes = useStyles();
  const [selfieStep, setSelfieStep] = useState('init');
  const [selfie, setSelfie] = useState(null);
  const [status, setStatus] = useState('idle');
  const { me, loadingMe } = useGetMe();
  const { selfie: selfieHelpData, desktop_img } = useGetHelpData(
    me?.user?.profile,
    me?.steps?.step_2?.name
  );
  const history = useHistory();
  const [webcamPic, setWebcamPic] = useState(null);
  const desktop = useMediaQuery('(min-width: 1300px)');

  const [hasCamera, setHasCamera] = useState(false);
  const { setDesktopImg } = useContext(Context);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then(setHasCamera(true))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (desktop_img) {
      setDesktopImg(desktop_img);
    }
  }, [desktop_img]);

  useEffect(() => {
    if (webcamPic) {
      const file = dataURLtoFile(webcamPic, 'webcam-img');
      setSelfie({ file, data_url: webcamPic });
    }
  }, [webcamPic]);

  const onUploadSelfie = () => {
    setStatus('pending');
    uploadSelfie(selfie.file)
      .then((res) => {
        toast.success(res.data.message);
        history.push('/documentation');
        setStatus('resolved');
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setStatus('rejected');
      });
  };

  if (loadingMe) {
    return <LoadingStep />;
  }

  const renderSelfieState = () => {
    if (selfieStep === 'init') {
      return (
        <>
          <Box flexGrow={1}>
            <Typography align="center" className={classes.text}>
              {selfieHelpData?.title}
            </Typography>

            <img
              src={selfieHelpData?.image}
              className={classes.selfieImg}
              alt="Document phone"
            />

            <Typography align="center" className={classes.description}>
              {selfieHelpData?.text}
            </Typography>

            <Box marginTop="24px" padding="0 20px">
              {selfieHelpData?.list?.map((step, index) => (
                <DescriptionItem
                  step={index + 1}
                  description={step}
                  key={index}
                />
              ))}
            </Box>
          </Box>
          <ButtonApp
            text="Continuar"
            onClick={() => setSelfieStep('selfie-image')}
          />
        </>
      );
    }
    if (selfieStep === 'selfie-image') {
      return (
        <>
          <Box flexGrow={1}>
            <Upload
              isWebCamActive={hasCamera && desktop}
              setWebcamPic={setWebcamPic}
              image={selfie}
              setImage={setSelfie}
              callback={() => setSelfieStep('selfie-preview')}
              onClose={() => setSelfieStep('init')}
              description=" Prepárate para la selfie!"
              inputCapture={'user'}
            />
            <Typography align="center" className={classes.text}>
              Prepárate para la selfie!
            </Typography>
          </Box>
        </>
      );
    }
    if (selfieStep === 'selfie-preview') {
      return (
        <>
          <Box flexGrow={1}>
            <Typography align="center" className={classes.text}>
              Esta es tu selfie!
            </Typography>
            <Box
              width="234px"
              height="234px"
              borderRadius="8px"
              border="1px solid #6F778240"
              padding="10px"
              display="flex"
              alignItems="center"
              margin="auto"
              justifyContent="center"
            >
              <img
                src={selfie?.data_url}
                alt="Selfie"
                style={{
                  width: 200,
                  height: '100%',
                  borderRadius: 5,
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Box>
          <ButtonApp
            text="Tomar foto nuevamente"
            variant="outlined"
            disabled={status === 'pending'}
            onClick={() => {
              setSelfieStep('selfie-image');
              setSelfie(null);
            }}
          />
          <ButtonApp
            text="Continuar"
            marginTop={0}
            onClick={onUploadSelfie}
            loading={status === 'pending'}
          />
        </>
      );
    }
  };

  return (
    <Box
      display="flex"
      padding="25px"
      flexDirection="column"
      minHeight="100vh"
      style={{ overflowY: 'auto' }}
    >
      <ProgressHeader
        title={me?.steps?.['step_2'].title}
        progress={me?.steps?.['step_2'].progress}
        steps={me?.steps}
        onBack={() => history.push('/document_id')}
      />

      {renderSelfieState()}
    </Box>
  );
};

export default Selfie;
