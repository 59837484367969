import { useContext, useEffect, useState } from 'react';
import { Context } from '../Context';
import getMe from '../services/getMe';
import { useHistory } from 'react-router-dom';

const useGetMe = () => {
  const [me, setMe] = useState([]);
  const { onSignOut } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    getMe()
      .then((res) => {
        setMe(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('getMe1: ', err);
        if (err.message === 'Unauthenticated.') {
          onSignOut();
          history.push('/');
        }
        setLoading(false);
      });
  }, []);

  return {
    me,
    setMe,
    loadingMe: loading,
  };
};

export default useGetMe;
