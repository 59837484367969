import { Box } from '@material-ui/core';
import React from 'react';
import ReactCodeInput from 'react-code-input';

const inputStyle = {
  background: '#6F778205',
  border: '1px solid #6F778240',
  height: 44,
  width: 44,
  borderRadius: 8,
  fontFamily: 'App-semi-bold',
  fontSize: 20,
  color: '#273240',
  outline: 'none',

  textAlign: 'center',
};

const SignContract = ({ value, onChange }) => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      margin="36px 0"
    >
      <ReactCodeInput
        type="number"
        inputMode="numeric"
        fields={6}
        inputStyle={inputStyle}
        value={value}
        onChange={(cd) => {
          onChange(cd);
        }}
      />
    </Box>
  );
};

export default SignContract;
