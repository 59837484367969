import { Box, makeStyles, Typography } from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { useHistory } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import ButtonApp from '../Components/ButtonApp';
import { Context } from '../Context';
import getMe from '../services/getMe';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 14,
    fontFamily: 'App-bold',
    color: '#273240',
    marginTop: 32,
    marginBottom: 24,
  },
}));

const WrongRequest = ({ auth }) => {
  const [loading, setLoading] = useState(false);
  const { onSignOut } = useContext(Context);
  const classes = useStyles();

  const history = useHistory();
  return (
    <Box
      width="100%"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="25px"
      role="alert"
      style={{ overflowY: 'auto' }}
    >
      <Box
        display="flex"
        width="75px"
        height="75px"
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        border="1px solid #00e39c80"
      >
        <NotificationImportantIcon htmlColor="#00e39c" fontSize="large" />
      </Box>
      <Typography className={classes.text}>
        No pudimos procesar tu solicitud
      </Typography>
      <ButtonApp
        text="Regresar"
        loading={loading}
        onClick={() => {
          setLoading(true);
          getMe()
            .then((res) => {
              const steps = res?.data?.steps;
              const keys = Object.keys(steps);
              // setAuth(true);
              for (const key of keys) {
                if (steps[key]?.status?.name === 'incomplete') {
                  if (
                    steps[key]?.title === 'Tu solicitud esta siendo procesada'
                  ) {
                    history.push('/submitted');
                    return;
                  }
                  history.push(`/${steps[key]?.name}`);
                  return;
                } else {
                  history.push('/submitted');
                }
              }
              setLoading(false);
            })
            .catch((err) => {
              onSignOut();
              history.push('/');
              toast.error(err.message);
              setLoading(false);
            });
          // history.push(auth ? '/documentation' : '/')
        }}
      />
    </Box>
  );
};

export default WrongRequest;
