import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { getConfig } from '../config/config';
import { setNestedComponentsDefaultState } from '../config/handlers';
import { Renderer } from '../config/Renderer';
import PickButton from './PickButton';

const RenderNestedComponents = ({ state, setState, components, help }) => {
  const [nestedComponents, setNestedComponents] = useState(components);

  useEffect(() => {
    setNestedComponentsDefaultState(components, setState);
  }, [nestedComponents]);

  const onSetNestedComponents = (componetsToAdd) => {
    const slicedCurrent = nestedComponents.slice(
      0,
      nestedComponents.length - 1
    );
    const componentsCloned = [
      ...slicedCurrent,
      ...componetsToAdd,
      nestedComponents[nestedComponents.length - 1],
    ];

    setNestedComponents(componentsCloned);
  };

  const config = useMemo(() => {
    return getConfig({
      state,
      setState,
      componentsFromServer: nestedComponents,
      help,
      setNestedComponents: onSetNestedComponents,
    });
  }, [nestedComponents, state, setState, onSetNestedComponents]);

  return (
    <Box display="block">
      <Renderer config={config} />
    </Box>
  );
};

const PickGroupButton = ({
  value,
  onChange,
  label,
  description,
  labelAlign = 'center',
  options,
  selectValue,
  selectKey,
  selectText,
  state,
  setState,
  helpOptions,
}) => {
  return (
    <Box marginBottom="25px">
      <Typography
        style={{ fontSize: 16, fontFamily: 'App-bold' }}
        align={labelAlign}
      >
        {label}
      </Typography>
      {description && (
        <Typography style={{ fontSize: 12, marginTop: 16 }}>
          {description}
        </Typography>
      )}
      <Box
        marginTop="32px"
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {typeof options?.[0] === 'string'
          ? options.map((option, index) => (
              <PickButton
                text={option[selectText]}
                value={option[selectValue]}
                key={selectKey ? option[selectKey] : index}
                onClick={() => onChange(option[selectValue])}
                selected={value}
              />
            ))
          : options?.map((option, index) => (
              <PickButton
                text={option[selectText]}
                value={option[selectValue]}
                key={selectKey ? option[selectKey] : index}
                onClick={() => onChange(option[selectValue])}
                selected={value}
              />
            ))}
      </Box>
      <Box style={{ marginTop: options?.fields > 0 ? 40 : 40 }}>
        {typeof options?.[0] === 'string'
          ? options.map((option, index) => (
              <Box
                key={
                  selectKey ? `${option[selectKey]}-nested` : `${index}-nested`
                }
              >
                {value === option[selectValue] &&
                  option?.fields?.length > 0 && (
                    <RenderNestedComponents
                      state={state}
                      setState={setState}
                      components={option?.fields}
                      help={helpOptions}
                    />
                  )}
              </Box>
            ))
          : options?.map((option, index) => (
              <Box
                key={
                  selectKey ? `${option[selectKey]}-nested` : `${index}-nested`
                }
              >
                {value === option[selectValue] &&
                  option?.fields?.length > 0 && (
                    <RenderNestedComponents
                      state={state}
                      setState={setState}
                      components={option?.fields}
                      help={helpOptions}
                    />
                  )}
              </Box>
            ))}
      </Box>
    </Box>
  );
};

export default PickGroupButton;
