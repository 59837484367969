import {
  Box,
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import greenMark from '../../assets/images/greenMark.png';

const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    maxHeight: 300,
    objectFit: 'contain',
    position: 'absolute',
    bottom: 0,
    right: 0,
    objectPosition: 'right',
    zIndex: 0,
  },
  lowEnd: {
    width: '100%',
    maxHeight: 200,
    height: 200,
    objectFit: 'contain',
    position: 'absolute',
    bottom: 0,
    right: 0,
    objectPosition: 'right',
    zIndex: 0,
  },

    text: {
        fontSize: 30,
        color: '#fff',
        fontFamily: 'App-bold',
        lineHeight: 1,
    },

    description: {
        fontSize: 14,
        color: '#fff',
        fontFamily: 'App-regular',
        marginBottom: 20,
    },
    step: {
        fontSize: 14,
        color: '#fff',
        fontFamily: 'App-semi-bold',
        zIndex: 1,
    },

    button: {
        textTransform: 'capitalize',
        color: '#5d62f1',
        background: '#fff !important',
        boxShadow: '0 3px 6px #66788033',
        borderRadius: 120,
        width: 250,
        '& .MuiButton-label': {
            fontSize: 13,
            fontFamily: 'App-bold',
            letterSpacing: '5px',
        },
        height: 42,
    },
}));

const steps = [
  'Verificamos su identidad.',
  'Actualiza la información de su comercio.',
  'Elige la cuenta en la que quiere recibir su plata.',
  'firma los términos y condiciones',
];

export default function Splash({ setShowSplash }) {
  const lowerEndMobiles = useMediaQuery('(max-height: 568px)');
  const ipad = useMediaQuery('(min-width: 768px)');
  const classes = useStyles();

  return (
    <Box
      style={{ background: '#5d62f1', overflowX: 'hidden' }}
      display="flex"
      minHeight="100vh"
      width="100%"
      flexDirection="column"
    >
      <Box
        padding="0 40px"
        marginTop="50px"
        paddingBottom="30px"
        minHeight="100vh"
        zIndex={1}
        flex={1}
      >
        <Typography
          className={classes.text}
          style={(ipad && { fontSize: 38 }) || {}}
        >
          Quiubo, <br />
          <span style={{ color: '#00e39c' }}>¡bienvenido!</span> <br />
          Aquí empieza su contratación <br /> en línea.
        </Typography>
        <Box
          style={{
            height: 5,
            width: 30,
            margin: '20px 0',
            background: '#00e39c',
          }}
        />
        <Typography className={classes.description}>
          En tan solo 15 minutos podra contratar el servicio de cobro con
          tarjeta sin embrollos ni papeleo, solo debera completar estos simples
          pasos:
        </Typography>
        {steps.map((step, index) => (
          <Box
            display="flex"
            alignItems="center"
            key={index}
            marginBottom="10px"
            zIndex={9999}
          >
            <Box
              color="#5d62f1"
              width={30}
              height={30}
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="22px"
              fontFamily="App-bold"
              marginRight="10px"
              minWidth={30}
              minHeight={30}
              style={{ background: '#00e39c' }}
            >
              {index + 1}
            </Box>
            <Typography className={classes.step}>{step}</Typography>
          </Box>
        ))}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="40px"
          zIndex={99}
          position="relative"
        >
          <Button
            className={classes.button}
            onClick={() => setShowSplash(false)}
          >
            CONTINUAR
          </Button>
        </Box>
        <img
          src={greenMark}
          alt="mark"
          className={lowerEndMobiles ? classes.lowEnd : classes.img}
        />
      </Box>
    </Box>
  );
}
