import { useEffect, useState } from 'react';
import gethelpData from '../services/getHelpData';

const useGetHelpData = (profile, step, noProfile) => {
  const [helpData, setHelpData] = useState([]);
  useEffect(() => {
    if (profile) {
      gethelpData(profile, step)
        .then((res) => setHelpData(res.data))
        .catch((err) => console.log(err));
    }
    if (noProfile) {
      gethelpData()
        .then((res) => setHelpData(res.data))
        .catch((err) => console.log(err));
    }
  }, [profile]);

  return {
    ...helpData,
  };
};

export default useGetHelpData;
