import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';

const LoadingStep = () => {
  return (
    <Box
      minHeight="100vh"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography
        style={{ fontSize: 22, fontFamily: 'App-bold', color: '#5d62f1' }}
        align="center"
      >
        Cargando
      </Typography>
      <CircularProgress
        style={{ color: '#5d62f1', marginTop: 10 }}
        size="30px"
      />
    </Box>
  );
};

export default LoadingStep;
