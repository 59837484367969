import { Box, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ProgressHeader from '../Components/ProgressHeader';
import useGetMe from '../hooks/useGetMe';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LoadingStep from '../Components/LoadingStep';
import ButtonApp from '../Components/ButtonApp';
import { Context } from '../Context';
import { useHistory } from 'react-router-dom';
import useGetHelpData from '../hooks/useGetHelpData';

const SuccessScreen = () => {
  const {me, loadingMe} = useGetMe();
  const {onSignOut, setDesktopImg} = useContext(Context);
  const [loading, setLoading] = useState('idle');
  const helpData = useGetHelpData(
    me?.user?.profile,
    me?.user?.profile === 'Persona Juridica'
      ? me?.steps?.step_11?.name
      : me?.steps?.step_10?.name
  );
  const history = useHistory();
  useEffect(() => {
    if (helpData?.desktop_img) {
      setDesktopImg(helpData?.desktop_img);
    }
  }, [helpData?.desktop_img]);

  const success =
    me?.user?.profile === 'Persona Juridica'
      ? me?.steps?.step_11
      : me?.steps?.step_10;

  const renderIcon = (icon) => {
    switch (icon) {
      case 'success':
        return (
          <CheckCircleOutlineIcon
            htmlColor="#00e39c"
            style={{fontSize: 90, marginTop: 30, marginBottom: 30}}
          />
        );
      case 'error':
        return (
          <ErrorOutlineIcon
            htmlColor="#dc0046"
            style={{fontSize: 90, marginTop: 30, marginBottom: 30}}
          />
        );

      default:
        break;
    }
  };

  const onFinish = async () => {
    setLoading('pending');
    try {
      setTimeout(() => {
        onSignOut();
        localStorage.clear();
        history.push('/');
        setLoading('resolved');
      }, 1500);
    } catch (error) {
      setLoading('rejected');
    }
  };

  if (loadingMe) {
    return <LoadingStep/>;
  }


  return (
    <Box
      padding="25px"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      style={{overflowY: 'auto'}}
    >
      <ProgressHeader steps={me?.steps}/>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="80vh"
      >
        <Typography
          style={{fontSize: 22, color: '#00e39c', fontFamily: 'App-bold'}}
          align="center"
        >
          {success?.title}
        </Typography>
        {renderIcon(success?.icon)}
        <Typography
          style={{
            fontSize: 16,
            color: '#00e39c',
            fontFamily: 'App-font-medium',
            marginTop: 10,
          }}
          align="center"
        >
          {success?.value}
        </Typography>
      </Box>
      <ButtonApp
        text="Finalizar"
        onClick={onFinish}
        loading={loading === 'pending'}
      />
    </Box>
  );
};

export default SuccessScreen;
