import { Box, Typography } from '@material-ui/core';
import React from 'react';
import moment from 'moment';

const EditInfo = (props) => {
  const { label, value, margin, isEdit, EditComponent, type, componentEdit } =
    props;

  const renderValue = () => {
    if (type === 'date') {
      return value ? moment(`${value}`).format('YYYY-MM-DD') : value;
    } else if (componentEdit === 'InputCurrencyBox' && value) {
      return new Intl.NumberFormat('de-DE').format(
        value?.toString().replace(/[\D\s\._\-]+/g, '')
      );
    } else {
      return value;
    }
  };
  return (
    <Box style={{ margin }}>
      {isEdit ? (
        <EditComponent {...props} />
      ) : (
        <Box marginBottom="20px">
          <Typography
            style={{
              fontSize: 12,
              fontFamily: 'App-semi-bold',
            }}
          >
            {label}
          </Typography>

          <Typography style={{ fontSize: 14 }}>
            {/* {typeof value === 'object'
              ? moment(`${value}`).format('YYYY-MM-DDT00:00:00Z')
              : value} */}
            {renderValue()}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EditInfo;
