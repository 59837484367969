import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  notSelected: {
    textTransform: 'capitalize',
    border: '1px solid #6F778226',
    borderRadius: 120,
    color: '#273240',
    padding: '0 44px',
    background: '#6F778219 !important',
    minWidth: 'fit-content',
    '& .MuiButton-label': {
      fontSize: 14,
      fontFamily: 'App-regular',
    },
    height: 42,
  },

  selected: {
    textTransform: 'capitalize',
    color: '#fff',
    borderWidth: 2,
    borderRadius: 120,
    padding: '0 44px',
    background: '#5D62F1 !important',
    minWidth: 'fit-content',

    '& .MuiButton-label': {
      fontSize: 14,
      fontFamily: 'App-regular',
    },
    height: 42,
  },
}));

const PickButton = ({
  onClick,
  marginTop,
  marginBottom,
  text,
  value,
  selected,
}) => {
  const classes = useStyles();
  return (
    <Button
      className={
        value?.toLowerCase() === selected?.toLowerCase()
          ? classes.selected
          : classes.notSelected
      }
      onClick={onClick}
      style={{ marginTop, marginBottom }}
    >
      {text}
    </Button>
  );
};

export default PickButton;
