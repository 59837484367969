import AddMore from '../Components/AddMore';
import ButtonApp from '../Components/ButtonApp';
import Documents from '../Components/Documents';
import EditInfo from '../Components/EditInfo';
import InputBox from '../Components/InputBox';
import InputCurrencyBox from '../Components/InputCurrencyBox';
import InputDate from '../Components/InputDate';
import LogicField from '../Components/LogicField';
import PhoneInputBox from '../Components/PhoneInput';
import PickGroupButton from '../Components/PickGroupButton';
import SelectBox from '../Components/SelectBox';
import SignContract from '../Components/SignContract';
import StepDescription from '../Components/StepDescription';
import {
  handleOnChangeInput,
  handleBooleanOnChange,
  handleOnChangeDate,
  handleOnChangePhoneInput,
  handleOnChangeInputCurrency,
  handleOnChangeFile,
  handleDeleteFile,
  handleChangeCode,
  onChangeCloneStateKeys, handleChangeCheckbox,
} from './handlers';
import InputCheckBox from "../Components/InputCheckBox";
import InputAddressBox from "../Components/InputAddressBox";
import InfoLink from "../Components/InfoLink";

const onCheckOptionalProps = (relatedData, optionsObj, componentState) => {
  if (!relatedData) {
    return null;
  }
  return { options: optionsObj[relatedData] };
};

const onAddPropsToComponent = ({
   Component,
   onChange,
   componentState,
   EditComponent,
   componentEnhanced,
   onResetState,
   setDefaultComponentState,
   setState,
   onClick,
   hasOptionalProps,
   canRenderNestedComponents,
   componentHasState = true,
   optionalProps = null,
   isLoading,
   help,
 }) => {
  if (typeof onClick === 'function') {
    componentEnhanced.onClick = onClick;
    componentEnhanced.component = Component;
  } else if (componentHasState) {
    componentEnhanced.value = componentState[componentEnhanced.name];

    componentEnhanced.component = Component;
    componentEnhanced.onResetState =
        typeof onResetState === 'function'
            ? onResetState(componentEnhanced.name, setState)
            : () => {};
    componentEnhanced.onChange =
        typeof onChange === 'function'
            ? onChange(componentEnhanced.name, setState, componentEnhanced.type)
            : () => {};
    if (EditComponent) {
      componentEnhanced.EditComponent = EditComponent;
      componentEnhanced.isEdit = componentState.isEdit;
    }

    if (hasOptionalProps) {
      if(componentEnhanced.relatedData == 'cities' && hasOptionalProps.options) {
        // console.log('componentState', componentState, componentEnhanced, componentEnhanced.name.includes("["));

        let groupId = 0;
        if(componentEnhanced.name.includes("[")) {
          groupId = componentEnhanced.name?.split('_')[1]
              .split('[')[1]
              .split(']')[0] || 0;
        }

        let groupField = componentState['special_'+groupId];
        let zone_id = groupField ? groupField.zone : null;
        let newOptions = [];
        //console.log('componentState', componentState, componentEnhanced, zone_id);

        hasOptionalProps.options.forEach((option) => {
          if(option['zone_id'] == zone_id) {
            option['parent_zone_id'] = zone_id;
            newOptions.push(option);
          }
        });
        componentEnhanced.options = newOptions;

      } else if(componentEnhanced.relatedData == 'zones' && hasOptionalProps.options) {
        let newOptions = [];

        hasOptionalProps.options.forEach((option) => {
          newOptions.push(option);
        });

        componentEnhanced.options = newOptions;
      } else {
        componentEnhanced.options = hasOptionalProps.options;
      }
    }

    if (canRenderNestedComponents) {
      componentEnhanced.state = componentState;
      componentEnhanced.setState = setState;
    }
    componentEnhanced.isLoading = isLoading;

    if (help) {
      componentEnhanced.helpOptions = help;
    }
  } else {
    componentEnhanced.component = Component;
    componentEnhanced.otherState = componentState;
    // console.log(componentState);
    // componentEnhanced.isEdit = componentState.isEdit;
  }
};

const getHandlerForEditInfoComponent = (component) => {
  switch (component) {
    case 'InputBox':
      return handleOnChangeInput;

    case 'InputDate':
      return handleOnChangeDate;

    case 'InputCurrencyBox':
      return handleOnChangeInput;

    case 'BooleanOptions':
      return handleBooleanOnChange;

    case 'SelectBox':
      return handleOnChangeInput;

    case 'PhoneInputBox':
      return handleOnChangePhoneInput;

    default:
      return null;
  }
};

const GetComponentFOrEditInfo = (componentName) => {
  switch (componentName) {
    case 'InputBox':
      return InputBox;

    case 'InputDate':
      return InputDate;

    case 'InputCurrencyBox':
      return InputCurrencyBox;

    case 'BooleanOptions':
      return PickGroupButton;

    case 'SelectBox':
      return SelectBox;

    case 'PhoneInputBox':
      return PhoneInputBox;

    default:
      return null;
  }
};

export const getConfig = ({
                            componentsFromServer = [],
                            state,
                            setState,
                            help,
                            setNestedComponents,
                            isLoading = false,
                          }) => {

  return componentsFromServer.map((component) => {
    const componentEnhanced = { ...component };
    switch (component.componentName) {
      case 'LogicField':
        onAddPropsToComponent({
          Component: LogicField,
          componentState: state,
          componentEnhanced,
          componentHasState: false,
        });
        break;
      case 'AddPartner':
        onAddPropsToComponent({
          Component: AddMore,
          componentEnhanced,
          componentHasState: false,
          onClick: onChangeCloneStateKeys(
              componentsFromServer,
              setNestedComponents
          ),
        });
        break;
      case 'InputBox':
        onAddPropsToComponent({
          Component: InputBox,
          onChange: handleOnChangeInput,
          componentState: state,
          componentEnhanced,
          setState,
        });
        break;
      case 'Address':
        onAddPropsToComponent({
          Component: InputAddressBox,
          onChange: handleOnChangeInput,
          componentState: state,
          componentEnhanced,
          setState,
        });
        break;
      case 'StepDescription':
        onAddPropsToComponent({
          Component: StepDescription,
          componentEnhanced,
          componentHasState: false,
        });
        break;
      case 'InfoLink':
        onAddPropsToComponent({
          Component: InfoLink,
          componentEnhanced,
          componentHasState: false,
        });
        break;
      case 'SignContract':
        onAddPropsToComponent({
          Component: SignContract,
          onChange: handleChangeCode,
          componentState: state,
          componentEnhanced,
          setState,
        });
        break;
      case 'InputCurrencyBox':
        onAddPropsToComponent({
          Component: InputCurrencyBox,
          onChange: handleOnChangeInputCurrency,
          componentState: state,
          componentEnhanced,
          setState,
        });
        break;

      case 'BooleanOptions':
        onAddPropsToComponent({
          Component: PickGroupButton,
          onChange: handleBooleanOnChange,
          componentState: state,
          componentEnhanced,
          setState,
          help,
          canRenderNestedComponents: true,
        });
        break;
      case 'PickGroupButton':
        onAddPropsToComponent({
          Component: PickGroupButton,
          onChange: handleBooleanOnChange,
          componentState: state,
          componentEnhanced,
          setState,
          help,
          canRenderNestedComponents: true,
        });
        break;

      case 'Document':
        onAddPropsToComponent({
          Component: Documents,
          onChange: handleOnChangeFile,
          componentState: state,
          componentEnhanced,
          onResetState: handleDeleteFile,
          setState,
          isLoading,
        });
        break;
      case 'SelectBox':
        // console.log('SelectBox', componentEnhanced.relatedData);
        onAddPropsToComponent({
          Component: SelectBox,
          onChange: handleOnChangeInput,
          componentState: state,
          hasOptionalProps: onCheckOptionalProps(
              componentEnhanced.relatedData,
              help,
              state
          ),
          componentEnhanced,
          setState,
        });
        break;
      case 'InputDate':
        onAddPropsToComponent({
          Component: InputDate,
          onChange: handleOnChangeDate,
          componentState: state,
          componentEnhanced,
          setState,
        });
        break;
      case 'PhoneInputBox':
        onAddPropsToComponent({
          Component: PhoneInputBox,
          onChange: handleOnChangePhoneInput,
          componentState: state,
          componentEnhanced,
          setState,
        });
        break;
      case 'EditInfo':
        onAddPropsToComponent({
          Component: EditInfo,
          onChange: getHandlerForEditInfoComponent(
              componentEnhanced?.componentEdit
          ),
          EditComponent: GetComponentFOrEditInfo(
              componentEnhanced?.componentEdit
          ),
          componentState: state,
          componentEnhanced,
          setState,
          canRenderNestedComponents: true,
          hasOptionalProps: onCheckOptionalProps(
              componentEnhanced.relatedData,
              help
          ),
        });
        break;
      case 'ButtonApp':
        onAddPropsToComponent({
          Component: ButtonApp,
          componentEnhanced,
          onClick: () => {},
        });
        break;
      case 'InputCheckBox':
        onAddPropsToComponent({
          Component: InputCheckBox,
          onChange: handleChangeCheckbox,
          componentEnhanced,
          componentState: state,
          setState,
        });
        break;

      default:
        return <div>Unsopported Component</div>;
    }

    return componentEnhanced;
  });
};
