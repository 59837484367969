import { Box, Typography } from '@material-ui/core';
import React from 'react';
import PhoneInput from 'react-phone-input-2';

const PhoneInputBox = ({
  value,
  onChange,
  label = 'Número de teléfono',
  error,
}) => {
  return (
    <Box marginBottom="25px">
      {label && (
        <Typography
          style={{
            fontFamily: 'App-semi-bold',
            fontSize: 12,
            margin: '25px 0 0px 0',
          }}
        >
          {label}
        </Typography>
      )}
      <PhoneInput
        country="co"
        masks={{
          co: '... ... ....',
        }}
        placeholder="+57 320 555 5555"
        specialLabel={false}
        value={value}
        onChange={(phone) => onChange(phone)}
        inputStyle={{
          padding: '18.5px 14px 8.5px 58px',
          border: 0,
          borderRadius: 0,
          outline: 0,
          borderBottom: '1px solid #6f778280',
          fontFamily: 'App-font-medium',
          width: '100%',
        }}
      />
      {error && (
        <Typography
          style={{ color: 'red', fontSize: 10, fontFamily: 'App-font-medium' }}
        >
          Este campo es requerido
        </Typography>
      )}
    </Box>
  );
};

export default PhoneInputBox;
