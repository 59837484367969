import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ButtonApp from '../../Components/ButtonApp';
import LoadingStep from '../../Components/LoadingStep';
import ProgressHeader from '../../Components/ProgressHeader';
import { getConfig } from '../../config/config';
import { setDefaultState } from '../../config/handlers';
import { Renderer } from '../../config/Renderer';
import { Context } from '../../Context';
import useGetHelpData from '../../hooks/useGetHelpData';
import useGetMe from '../../hooks/useGetMe';
import onSaveStep from '../../services/saveStep';

const LegalRepresentative = () => {
  const { me, loadingMe } = useGetMe();
  const [state, setState] = useState({});
  const helpData = useGetHelpData(me?.user?.profile, me?.steps?.step_4?.name);
  const [loading, setLoading] = useState('idle');
  const history = useHistory();

  const { setDesktopImg } = useContext(Context);

  useEffect(() => {
    if (helpData?.desktop_img) {
      setDesktopImg(helpData?.desktop_img);
    }
  }, [helpData?.desktop_img]);

  useEffect(() => {
    if (me) {
      if (me?.steps?.step_4?.order === 4) {
        setDefaultState(me?.steps?.step_4?.body, state, setState, {
          isEdit: false,
        });
      }
    }
  }, [me]);

  const config = useMemo(() => {
    if (me) {
      return getConfig({
        state,
        setState,
        componentsFromServer: me?.steps?.step_4?.body,
        help: helpData,
      });
    }
  }, [state, me, helpData]);

  // console.log('Aqui: ', helpData, config);

  const saveStep = () => {
    setLoading('pending');
    onSaveStep({ ...state, view: 'legal_representative' })
        .then((data) => {
          setLoading('resolved');
          history.push('/company_information');
        })
        .catch((err) => {
          const errorKeys = Object.keys(err.response?.data?.errors);

          const error = errorKeys.map((key) => (
              <Typography
                  style={{ fontSize: 12, fontFamily: 'App-regular' }}
              >{`⋅ ${key} - ${err.response?.data?.errors[key][0]}`}</Typography>
          ));

          toast.error(<Box>{error}</Box>, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading('rejected');
        });
  };

  if (loadingMe) {
    return <LoadingStep />;
  }

  return (
      <Box
          padding="25px"
          display="flex"
          flexDirection="column"
          minHeight="100vh"
          style={{ overflowY: 'auto' }}
      >
        <Box flexGrow={1}>
          <ProgressHeader
              title={me?.steps?.step_4?.title}
              progress={me?.steps?.step_4?.progress}
              onBack={() => history.push('/bank_account')}
              steps={me?.steps}
          />
          <Box marginTop="30px">
            <Renderer config={config} />
          </Box>
        </Box>

        {!state.isEdit && (
            <ButtonApp
                text="Continuar"
                onClick={saveStep}
                loading={loading === 'pending'}
            />
        )}
      </Box>
  );
};

export default LegalRepresentative;
