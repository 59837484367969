import { Box, Typography } from '@material-ui/core';
import React from 'react';

const InfoLink = ({ helperText, value, label }) => (
  <Box style={{ marginBottom: 16 }}>

      <a
          style={{
              fontFamily: 'App-semi-bold',
              fontSize: 12,
              color: '#00e39c',
              cursor: 'pointer',
              align: 'center'
          }}
          href={label}
          target="_blank"
      >
          {helperText}
      </a>

  </Box>
);

export default InfoLink;
