import axios from 'axios';

function resetToken() {
  return axios.create({
    baseURL: `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_API_KEY}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

const config = {
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    'content-type': 'application/json',
  },
  responseType: 'json',
};

const fileConfig = {
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    'content-type': 'multipart/form-data',
  },
  responseType: 'json',
};

const axiosApiInstance = axios.create(config);

const axiosFilesIntance = axios.create(fileConfig);

// axiosFilesIntance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// axiosFilesIntance.defaults.baseURL = process.env.REACT_APP_ENDPOINT;

axiosApiInstance.interceptors.request.use(
  async (cfg) => {
    const token = await sessionStorage.getItem('pay-mobile-token');
    if (token) {
      cfg.headers.Authorization = token;
    }
    return cfg;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
      console.log('axiosApiInstance', error);
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject('Network Error');
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
axiosFilesIntance.interceptors.request.use(
  async (cfg) => {
    const token = await sessionStorage.getItem('pay-mobile-token');
    if (token) {
      cfg.headers.Authorization = token;
    }
    return cfg;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axiosFilesIntance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject('Network Error');
    }
    if (error.response.status === 401 && !originalRequest._retry) {
        console.log('-> axiosFilesIntance');
      originalRequest._retry = true;
      const refreshToken = sessionStorage.getItem('pay-mobile-refreshToken');
      const { data } = await resetToken().post(
        '',
        `grant_type=refresh_token&refresh_token=${refreshToken}`
      );
      originalRequest.headers['Authorization'] = data.id_token;

      sessionStorage.setItem('pay-mobile-token', data.id_token);
      sessionStorage.setItem('pay-mobile-refreshToken', data.refresh_token);

      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const appEndpointWithToken = () => axiosApiInstance;
export const appEndpointWithFiles = () => axiosFilesIntance;

export const appEndpoint = () =>
  axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  });
