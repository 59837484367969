import { Box, makeStyles, Typography } from '@material-ui/core';

import React, { useContext, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import ButtonApp from '../../Components/ButtonApp';
import PageHeader from '../../Components/PageHeader';
import { toast } from 'react-toastify';
import registerUser from '../../services/RegisterUser';
import getMe from '../../services/getMe';
import { Context } from '../../Context';
import { useHistory, useLocation } from 'react-router-dom';
import registerPhoneOtp from "../../services/registerPhoneOtp";
import registerEmailOtp from "../../services/registerEmailOtp";
import loginPhoneOtp from "../../services/loginPhoneOtp";
import loginOtp from "../../services/login";

import { useCountdownTimer } from 'use-countdown-timer'


const useStyles = makeStyles((theme) => ({
  container: {
    padding: 25,
    flexDirection: 'column',
    display: 'flex',
    minHeight: '100vh',
  },
  inputCode: {},
}));

const inputStyle = {
  background: '#6F778205',
  border: '1px solid #6F778240',
  height: 44,
  width: 44,
  borderRadius: 8,
  fontFamily: 'App-semi-bold',
  fontSize: 20,
  color: '#273240',
  outline: 'none',
  textAlign: 'center',
};

const VerifyPhone = () => {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const location = useLocation();
  const [status, setStatus] = useState('idle');
  const {setAuth, setFormCompleted, setCheckCompletedStep, isClaro} =
    useContext(Context);
  const history = useHistory();
  let userData;
  let pageAction = (new URLSearchParams(window.location.search)).get("action");
  const [actions, setActions] = useState([])
  const [timer] = useState(1000 * 60)

  const [showCountdown, setShowCountdown] = useState(true)
  const {countdown, start, reset, pause, isRunning} = useCountdownTimer({
    timer,
    autostart: true,
    onExpire: () => {
      setActions((actions) => [...actions, 'Expire Callback']);
      console.log('onExpire');
      setShowCountdown(false);
    },
    onReset: () => {
      setActions((actions) => [...actions, 'Reset Callback']);
      console.log('onReset');
    },
  })


  const logAction = (message, action) => {
    setActions((actions) => [...actions, message])
    action()
  }

  userData = JSON.parse(localStorage.getItem('user-data'));

  const {
    phoneNumber,
    email,
    companyName,
    nit,
    profile,
    first_name,
    second_name,
    first_last_name,
    second_last_name,
    documentId,
    expedition_date,
    referral_code,
    ciiu,
    terms,
    isSignup,
    documentType,
  } = userData;

  /*const setCodeData = (event) => {
      code = event;
      console.log('setCodeData', code);
  }*/
  const onResendVerificationCode = async () => {
    //resetCountDown = true;

    if (pageAction == 'signIn') {
      loginPhoneOtp(phoneNumber.slice(2))
        .then((res) => {
          toast.success(`Código de verificación enviado al celular exitosamente`);

          logAction('Start', start);
          setShowCountdown(true);

        })
        .catch((err) => {
          toast.error(err, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });


    } else {
      registerPhoneOtp(phoneNumber.slice(2))
        .then((res) => {
          toast.success(`Código de verificación enviado al celular exitosamente`);

          logAction('Start', start);
          setShowCountdown(true);

        })
        .catch((err) => {
          toast.error(err, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const onSendVerificationCodeEmail = async () => {

    registerEmailOtp(email)
      .then((res) => {
        console.log(res);
        toast.success(`Su codigo de verificación se envio satisfactoriamente al correo`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onConfirmCode = () => {
    setStatus('pending');

    console.log('onConfirmCode', code);

    let otp = code;
    if (isSignup) {
      registerUser(
        profile,
        first_name,
        second_name,
        first_last_name,
        second_last_name,
        companyName,
        documentId,
        expedition_date,
        nit,
        ciiu,
        null,
        phoneNumber.slice(2),
        email,
        terms,
        'phone',
        referral_code,
        documentType,
        otp,
        isClaro
      )
        .then((res) => {
          console.log(res);
          toast.success(`El usuario se ha creado satisfactoriamente`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setAuth(true);

          sessionStorage.setItem('pay-mobile-token', res.data.token.token_type + ' ' + res.data.token.access_token);
          //sessionStorage.setItem('pay-mobile-refreshToken', user.refreshToken);

          history.push('/document_id');
          setCheckCompletedStep(false);
        }).catch((err) => {
          const errorKeys = Object.keys(err?.errors);

          let message = '';

          for (const key of errorKeys) {
            message += `⋅ ${key} - ${err?.errors[key]}
                  `;
          }
          toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          //onSignOut();
          setStatus('rejected');
        });
    } else {
      loginOtp(phoneNumber.slice(2), otp)
        .then((res) => {
          console.log(res);

          sessionStorage.setItem('pay-mobile-token', res.data.token.token_type + ' ' + res.data.token.access_token);

          getMe()
            .then((res) => {
              const steps = res?.data?.steps;
              const keys = Object.keys(steps);
              setCheckCompletedStep(false);
              setAuth(true);
              for (const key of keys) {
                if (steps[key]?.status?.name === 'incomplete') {
                  if (steps[key]?.title === 'Tu solicitud esta siendo procesada') {
                    history.push('/submitted');
                    return;
                  }
                  console.log(steps[key], key);
                  history.push(`/${steps[key]?.name}`);
                  return;
                } else if (
                  steps[keys[keys.length - 1]]?.status?.name === 'complete' ||
                  steps[key]?.title === 'Tu solicitud esta siendo procesada'
                ) {
                  console.log('2:', steps, key);
                  setFormCompleted(true);
                  history.push('/submitted');
                }
              }
            })
            .catch((err) => {
              toast.error(err.message);
            }).finally(() => {
              setStatus('resolved');
            });

        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setStatus('resolved');
        })
    }

  };

  return (
    <Box className={classes.container}>
      <Box flexGrow={1}>
        <div id="recaptcha-2-container" style={{visibility: 'hidden'}}/>
        <PageHeader
          onClick={() => {
            if (isSignup) {
              history.push('/signup', {
                accountType:
                  profile === 'legal' ? 'Persona Jurídica' : 'Persona Natural',
                referral_code,
              });
            } else {
              history.push('/');
            }
          }}
        />

        <Typography style={{fontFamily: 'App-bold', fontSize: 20}}>
          Le enviamos un mensaje de texto con su código.
        </Typography>

        <Typography style={{fontSize: 13, margin: '8px 0 0'}} align="center">
          Por favor verifique sus SMS y coloque el código de 6 dígitos que
          enviamos al número
        </Typography>

        <Typography
          style={{fontFamily: 'App-bold', fontSize: 13, marginBottom: '20px'}}
          align="center"
        >
          +
          {phoneNumber?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, '$1 $2 $3 $4')}
        </Typography>

        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="36px 0"
        >
          <ReactCodeInput
            type="number"
            fields={6}
            inputMode="numeric"
            inputStyle={inputStyle}
            onChange={(cd) => {
              setCode(cd);
            }}
          />
        </Box>


        <div>
          {showCountdown ?
            <Typography
              style={{
                fontFamily: 'App-font-medium',
                fontSize: 13,
                marginBottom: '13px',
                color: '#6F7782',
              }}
              align="center"
            > Se habilitará un nuevo codigo en: {countdown / 1000} </Typography>
            :
            <div>
              <Typography
                style={{
                  fontFamily: 'App-font-medium',
                  fontSize: 13,
                  marginBottom: '13px',
                  color: '#6F7782',
                }}
                align="center"
              >
                ¿No ha recibido el código?
              </Typography>
              <Typography
                style={{
                  fontFamily: 'App-semi-bold',
                  fontSize: 12,
                  color: '#00e39c',
                  cursor: 'pointer',
                }}
                align="center"
                onClick={onResendVerificationCode}
              >
                Reenviar código al celular
              </Typography>

              {pageAction != 'signIn' ? (
                <div>
                  <Typography style={{fontSize: 12, marginTop: 25, marginBottom: '13px'}} align="center">
                    ¿No te llego el código de verificación al número celular? <br/>

                  </Typography>
                  <Typography
                    style={{
                      fontFamily: 'App-semi-bold',
                      fontSize: 12,
                      color: '#00e39c',
                      cursor: 'pointer',
                    }}
                    align="center"
                    onClick={onSendVerificationCodeEmail}
                  >
                    Enviar al Correo Electrónico
                  </Typography>
                </div>

              ) : (
                ''
              )}

            </div>
          }

        </div>

      </Box>

      <ButtonApp
        disabled={status === 'pending' || code.length < 6}
        text="Verificar código"
        onClick={onConfirmCode}
        loading={status === 'pending'}
      />
    </Box>
  );
};

export default VerifyPhone;
