import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { BootstrapTooltip } from '../Pages/CreateAccount/CreateAccount';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DocumentStatus from './DocumentStatus';
import ImageUploading from 'react-images-uploading';
import { converFromByteToMb } from '../utils/utils';
import { DropzoneArea } from 'material-ui-dropzone';
import styled from 'styled-components';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import formatTxt from '../utils/formatText';

function readFile(file) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();

    reader.onload = function (event) {
      let _event$target;

      resolve(
        event === null || event === void 0
          ? void 0
          : (_event$target = event.target) === null || _event$target === void 0
          ? void 0
          : _event$target.result
      );
    };

    reader.onerror = function (event) {
      reader.abort();
      reject(event);
    };

    reader.readAsDataURL(file);
  });
}

const useStyles = makeStyles(() => ({
  certificateWrapper: {
    border: '1px solid #6F778240',
    borderRadius: 4,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 300,
    width: '100%',
    height: 60,
    cursor: 'pointer',
  },

  documentImg: {
    width: 71,
    height: 95,
    marginRight: 10,
  },

  deleteButton: {
    border: '1px solid #FF3939',
    color: '#FF3939',
    borderRadius: 120,
    padding: '6px 16px',
    fontSize: 12,
    fontFamily: 'App-font-medium',
  },
}));

const Documents = ({
  value,
  title,
  onChange: onChangeFile,
  helperText,
  onResetState,
  status,
  isLoading,
}) => {
  const classes = useStyles();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);
  const [image, setImage] = useState(null);
  const ref = React.useRef();

  useEffect(() => {
    if (value) {
      setShowCertificate(true);
    }
  }, [value]);

  const onChange = (file) => {
    onChangeFile(file[0]);

    if (file[0].type !== 'application/pdf') {
      readFile(file[0]).then((res) => setImage(res));
    }
    setShowCertificate(true);
  };

  const onDeleteImage = () => {
    onResetState();
    setShowCertificate(false);
  };

  if (showCertificate) {
    return (
      <Box margin="20px 0 0">
        <Box
          className={classes.certificateWrapper}
          style={{
            height: 127,
            justifyContent: 'unset',
            alignItems: 'flex-start',
            marginBottom: 8,
          }}
        >
          {value?.type === 'application/pdf' ||
          value?.name?.includes('.pdf') ? (
            <PictureAsPdfIcon
              htmlColor="red"
              style={{ fontSize: 71, marginRight: 10 }}
            />
          ) : (
            <img
              src={image || value?.original}
              alt="document"
              className={classes.documentImg}
            />
          )}
          <Box>
            <Typography style={{ fontSize: 12, fontFamily: 'App-font-medium' }}>
              {formatTxt(value?.file?.name || value?.name, 15)}
            </Typography>
            <Typography
              style={{
                fontSize: 12,
                color: '#6F7782',
                textTransform: 'uppercase',
                marginBottom: 5,
              }}
            >
              {converFromByteToMb(value?.size)} mb
            </Typography>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <CheckCircleIcon
                htmlColor="#00e39c"
                style={{ marginRight: 5, fontSize: 15 }}
              />
              <Typography style={{ fontSize: 12 }}>
                Imagen subida exitosamente
              </Typography>
            </Box>
            <button
              className={classes.deleteButton}
              onClick={onDeleteImage}
              disabled={isLoading}
            >
              Eliminar
            </button>
          </Box>
        </Box>
        <DocumentStatus
          status={status?.status}
          statusText={status?.name}
          background={status?.background}
          color={status?.color}
        />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      margin="20px 0 0"
    >
      <Box
        className={classes.certificateWrapper}
        onClick={(e) => {
          if (['svg', 'path'].includes(e.target.tagName)) {
            return;
          }
          ref.current.click();
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <AccountBalanceIcon
              style={{ marginRight: 10 }}
              htmlColor="#00e39c"
            />
            <Box>
              <Typography
                style={{ fontSize: 14, fontFamily: 'App-font-medium' }}
              >
                {title}
              </Typography>
              <Typography style={{ fontSize: 12, color: '#6F7782' }}>
                Tamaño máximo 10MB
              </Typography>
            </Box>
          </Box>
          {/* <ImageUploading
            // multiple
            value={value}
            onChange={onChange}
            dataURLKey="data_url"
            acceptType={['pdf', 'jpg', 'png']}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <KeyboardArrowRightIcon
                htmlColor="#6F7782"
                onClick={onImageUpload}
                {...dragProps}
                style={{ cursor: 'pointer' }}
              />
            )}
          </ImageUploading> */}
          <BoxContainer>
            <DropzoneArea
              acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
              maxFileSize={10000000}
              onChange={(fl) => {
                if (fl.length > 0) {
                  onChange(fl);
                }
                // if (fl[0]) {
                //   readFile(fl[0]).then((res) => setImgBs64(res));
                // }
                // setImage(fl);
              }}
              filesLimit={1}
              dropzoneText=""
              showPreviewsInDropzone={false}
              Icon={() => (
                <button ref={ref}>
                  <KeyboardArrowRightIcon
                    htmlColor="#6F7782"
                    // onClick={onImageUpload}
                    // {...dragProps}
                    style={{ cursor: 'pointer' }}
                  />
                </button>
              )}
            />
          </BoxContainer>
        </Box>
      </Box>
      <BootstrapTooltip title={helperText} open={openTooltip}>
        <HelpIcon
          fontSize="default"
          htmlColor="#00e39c"
          style={{ marginLeft: 10, cursor: 'pointer', outline: 0 }}
          onClick={() => setOpenTooltip(!openTooltip)}
        />
      </BootstrapTooltip>
    </Box>
  );
};

const BoxContainer = styled(Box)`
  .MuiDropzoneArea-root {
    height: 25px !important;

    min-height: 24px !important;
    max-height: 150px;
    width: 24px !important;
    border-color: transparent !important;
    background-color: transparent !important;
    outline: 0 !important;
    .MuiDropzoneArea-textContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
`;

export default Documents;
