import {
  Box,
  LinearProgress,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useContext, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { Link, useHistory } from 'react-router-dom';
import ButtonApp from './ButtonApp';
import { Context } from '../Context';

const useStyles = makeStyles(() => ({
  boxModal: {
    minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'auto',
    width: '100%',
    padding: 24,
    backgroundColor: 'rgba(255,255,255,1)',
    position: 'fixed',
    zIndex: 10,
    left: 0,
    bottom: 0,
    top: 0,
  },
}));

const ProgressHeader = ({ title, onBack, progress, steps }) => {
  const [open, setOpen] = useState(false);
  const lowerEndMobiles = useMediaQuery('(max-height: 568px)');
  const otherlowerEndMobiles = useMediaQuery('(max-height: 670px)');
  const { onSignOut } = useContext(Context);
  const [loading, setLoading] = useState('idle');
  const history = useHistory();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  const onFinish = async () => {
    setLoading('pending');
    try {
      setTimeout(() => {
        onSignOut();
        setOpen(false);
        history.push('/');
        setLoading('resolved');
      }, 1500);
    } catch (error) {
      setLoading('rejected');
    }
  };

  const checkIfIsSubmitted = () => {
    const stepKeys = Object.keys(steps);

    const stepu = stepKeys.find(
      (step) => steps[step].name === 'view_signature'
    );

    if (steps[stepu]?.status?.name === 'complete') {
      return true;
    }
    return false;
  };

  const classes = useStyles();
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="16px"
      >
        {typeof onBack === 'function' ? (
          <ArrowBackIosIcon
            htmlColor="#273240"
            onClick={onBack}
            style={{ width: 20, height: 20 }}
          />
        ) : (
          <Box />
        )}

        <Typography style={{ fontSize: 14, fontFamily: 'App-semi-bold' }}>
          {title}
        </Typography>

        <MoreVertIcon
          style={{ cursor: 'pointer' }}
          htmlColor="#273240"
          onClick={() => setOpen(true)}
        />
      </Box>
      {progress && (
        <Box padding="0 25px" display="flex" alignItems="center">
          <Box
            minWidth={10}
            minHeight={10}
            style={{ background: '#5D62F1' }}
            borderRadius="50%"
            border="1px solid #5D62F1"
          ></Box>
          <Box width="100%">
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Box
            minWidth={10}
            minHeight={10}
            style={{ background: '#5D62F1' }}
            borderRadius="50%"
            border="1px solid #5D62F1"
          ></Box>
        </Box>
      )}

      <AnimatePresence>
        {open && (
          <motion.div
            initial={{
              y: '60vh',
            }}
            animate={{
              y: lowerEndMobiles ? '0vh' : otherlowerEndMobiles ? '0vh' : 0,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{
              y: '100vh',
              transition: {
                duration: 0.5,
              },
            }}
            className={classes.boxModal}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom="28px"
            >
              <Typography style={{ fontFamily: 'App-bold' }}>
                Lista de chequeo de registro
              </Typography>
              <CancelIcon
                htmlColor="#273240"
                style={{ fontsize: 18, cursor: 'pointer' }}
                onClick={() => setOpen(false)}
              />
            </Box>

            {Object.keys(steps).map((step, index) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="18px"
                key={index}
                borderBottom="1px solid #151C260D"
                paddingBottom="10px"
                height="48.6px"
              >
                {checkIfIsSubmitted() ? (
                  <>
                    {steps[step].status?.name !== 'incomplete' && (
                      <Typography
                        style={{ fontFamily: 'App-font-medium', fontSize: 14 }}
                      >
                        {steps[step].title}
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    {['document_id', 'selfie', 'documentation'].includes(
                      steps[step].name
                    ) ? (
                      <Typography
                        style={{
                          fontFamily: 'App-font-medium',
                          fontSize: 14,
                        }}
                      >
                        {steps[step].title}
                      </Typography>
                    ) : (
                      <>
                        {steps[step].status?.name !== 'incomplete' && (
                          <Link to={`/${steps[step].name}`}>
                            <Typography
                              style={{
                                fontFamily: 'App-font-medium',
                                fontSize: 14,
                              }}
                            >
                              {steps[step].title}
                            </Typography>
                          </Link>
                        )}

                        {steps[step].status?.name === 'incomplete' && (
                          <Typography
                            style={{
                              fontFamily: 'App-font-medium',
                              fontSize: 14,
                            }}
                          >
                            {steps[step].title}
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                )}

                {steps[step].status?.name === 'complete' && (
                  <CheckIcon style={{ fontSize: 15 }} htmlColor="#00e39c" />
                )}
                {steps[step].status?.name !== 'complete' && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <ScheduleIcon
                      htmlColor="#6F7782"
                      style={{ fontSize: 16, marginBottom: 4 }}
                    />
                    <Typography
                      style={{
                        color: '#6F7782',
                        fontSize: 12,
                        fontFamily: 'App-regular',
                      }}
                    >
                      {steps[step].status?.description}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}

            <ButtonApp
              text="Cerrar sesión"
              onClick={onFinish}
              loading={loading === 'pending'}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default ProgressHeader;
