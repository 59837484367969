import { Box, Typography } from '@material-ui/core';
import React from 'react';

const LogicField = ({ label, field, fieldToCompare, operator, otherState }) => {
  const onCompare = () => {
    switch (operator) {
      case '+':
        return (
          parseInt(otherState[`field_${field}`]?.replace(/\./g, '')) ||
          0 +
            parseInt(
              otherState[`field_${fieldToCompare}`]?.replace(/\./g, '')
            ) ||
          0
        );
      case '-':
        return (
          parseInt(otherState[`field_${field}`]?.replace(/\./g, '')) -
          parseInt(otherState[`field_${fieldToCompare}`]?.replace(/\./g, ''))
        );
      case '*':
        return (
          parseInt(otherState[`field_${field}`]?.replace(/\./g, '')) ||
          0 *
            parseInt(
              otherState[`field_${fieldToCompare}`]?.replace(/\./g, '')
            ) ||
          0
        );
      case '/':
        return (
          parseInt(otherState[`field_${field}`]?.replace(/\./g, '')) ||
          0 /
            parseInt(
              otherState[`field_${fieldToCompare}`]?.replace(/\./g, '')
            ) ||
          0
        );

      default:
        throw new Error('Unsupported Logic Operator');
    }
  };

  return (
    <Box style={{ marginBottom: 16 }}>
      {label && (
        <Typography
          style={{
            fontSize: 12,
            fontFamily: 'App-semi-bold',
          }}
        >
          {label}
        </Typography>
      )}
      <Box display="flex">
        {otherState?.isEdit && (
          <Typography
            style={{ color: '#27324080', fontSize: 14, marginRight: 6 }}
          >
            $
          </Typography>
        )}
        <Typography
          style={{
            fontSize: 14,
            fontFamily: otherState?.isEdit ? 'App-font-medium' : 'App-regular',
            color: '#273240',
          }}
        >
          {new Intl.NumberFormat('de-DE').format(
            isNaN(onCompare()) ? 0 : onCompare()
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default LogicField;
