import { appEndpoint } from '../utils/axios';

const loginOtp = async (phone, otp) => {
    const dataLoginOtp = {
        phone,
        otp
    }
    try {
        const res = await appEndpoint().post('/login', dataLoginOtp);
        return res;
    } catch (error) {
        throw error.response.data;
    }
};

export default loginOtp;