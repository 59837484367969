import { appEndpoint } from '../utils/axios';

const gethelpData = async (profile, step) => {
  const URL = '/help';
  const config = {};

  if (profile) {
    config.profile = profile;
  }

  if (step) {
    config.step = step;
  }
  try {
    const res = await appEndpoint().get(URL, { params: config });
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export default gethelpData;
