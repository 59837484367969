import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ProgressHeader from '../../Components/ProgressHeader';
import DescriptionItem from '../../Components/DescriptionItem';
import ButtonApp from '../../Components/ButtonApp';
import Upload from '../../Components/Upload';
import uploadDocumentId from '../../services/uploadDocumentId';
import { toast } from 'react-toastify';
import useGetMe from '../../hooks/useGetMe';
import useGetHelpData from '../../hooks/useGetHelpData';
import LoadingStep from '../../Components/LoadingStep';
import { Context } from '../../Context';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: 36,
    fontSize: 14,
    fontFamily: 'App-semi-bold',
    marginBottom: 24,
  },

  documentImg: {
    width: '100%',
    margin: '0 auto',
    height: 152,
    objectFit: 'contain',
    display: 'flex',
  },

  description: {
    fontSize: 12,
    marginTop: 20,
    padding: '0 40px',
  },
}));

const DocumentInstructions = () => {
  const classes = useStyles();
  const history = useHistory();
  const [documentStep, setDocumentStep] = useState('init');
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [status, setStatus] = useState('idle');
  const { me, loadingMe } = useGetMe();
  const { document_id, desktop_img } = useGetHelpData(
    me?.user?.profile,
    me?.steps?.step_1?.name
  );
  const { setDesktopImg } = useContext(Context);

  useEffect(() => {
    if (desktop_img) {
      setDesktopImg(desktop_img);
    }
  }, [desktop_img]);

  const onConfirmImgs = () => {
    setStatus('pending');
    uploadDocumentId(frontImage.file, backImage.file)
      .then((res) => {
        setStatus('resolved');

        toast.success('Los documentos se han guardado satisfactoriamente.');
        history.push('/selfie');
      })
      .catch((err) => {
        setStatus('rejected');
        toast.error(err.response.data.message);
      });
  };

  if (loadingMe) {
    return <LoadingStep />;
  }

  const renderDocumentSteps = () => {
    if (documentStep === 'init') {
      return (
        <>
          <Box flexGrow={1}>
            <Typography align="center" className={classes.text}>
              {document_id?.title}
            </Typography>

            <img
              src={document_id?.image}
              className={classes.documentImg}
              alt="Document phone"
            />

            <Typography align="center" className={classes.description}>
              {document_id?.text}
            </Typography>

            <Box marginTop="24px" padding="0 20px">
              {document_id?.list?.map((step, index) => (
                <DescriptionItem
                  step={index + 1}
                  description={step}
                  key={index}
                />
              ))}
            </Box>
          </Box>
          <ButtonApp
            text="Continuar"
            onClick={() => setDocumentStep('front-image')}
          />
        </>
      );
    }

    if (documentStep === 'front-image') {
      return (
        <Box flexGrow={1}>
          <Upload
            onClose={() =>
              frontImage
                ? setDocumentStep('front-image-preview')
                : setDocumentStep('init')
            }
            image={frontImage}
            setImage={setFrontImage}
            callback={() => {
              // if (backImage) {
              //   setDocumentStep('documents-preview');
              // } else {
              //   setDocumentStep('back-image');
              // }
              setDocumentStep('front-image-preview');
            }}
            includePlaholder="front"
            description="Escanee la parte delantera de su identificación"
            inputCapture={'environment'}
          />

          <Typography align="center" className={classes.text}>
            Escanee la parte delantera de su identificación
          </Typography>
        </Box>
      );
    }

    if (documentStep === 'front-image-preview') {
      return (
        <>
          <Box flexGrow={1} marginTop="30px">
            {frontImage && (
              <Box
                width="100%"
                height="148px"
                borderRadius="8px"
                border="1px solid #6F778240"
                padding="10px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={frontImage.data_url}
                  alt="Front Document"
                  style={{ width: 200, height: '100%', objectFit: 'contain' }}
                />
              </Box>
            )}
            <ButtonApp
              text="Tomar foto nuevamente"
              variant="outlined"
              marginTop={20}
              onClick={() => {
                setDocumentStep('front-image');
                setFrontImage(null);
              }}
            />
          </Box>
          <ButtonApp
            text="Escanear foto posterior"
            marginTop={20}
            onClick={() => {
              setDocumentStep('back-image');
            }}
          />
        </>
      );
    }
    if (documentStep === 'back-image') {
      return (
        <Box flexGrow={1}>
          <Upload
            onClose={() => setDocumentStep('front-image-preview')}
            image={backImage}
            setImage={setBackImage}
            callback={() => setDocumentStep('documents-preview')}
            includePlaholder="back"
            description="Escanee la parte posterior de su identificación"
            inputCapture={'environment'}
          />
          <Typography align="center" className={classes.text}>
            Escanea la parte posterior de tu identificación
          </Typography>

          {frontImage && (
            <Box
              width="100%"
              height="148px"
              borderRadius="8px"
              border="1px solid #6F778240"
              padding="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={frontImage.data_url}
                alt="Front Document"
                style={{ width: 200, height: '100%', objectFit: 'contain' }}
              />
            </Box>
          )}
          {backImage && (
            <Box
              width="100%"
              height="148px"
              borderRadius="8px"
              border="1px solid #6F778240"
              padding="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={backImage.data_url}
                alt="back Document"
                style={{ width: 200, height: '100%', objectFit: 'contain' }}
              />
            </Box>
          )}
        </Box>
      );
    }
    if (documentStep === 'documents-preview') {
      return (
        <>
          <Box flexGrow={1}>
            <Typography align="center" className={classes.text}>
              Escanee la parte posterior de su identificación
            </Typography>

            <Box
              width="100%"
              height="148px"
              borderRadius="8px"
              border="1px solid #6F778240"
              padding="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={frontImage.data_url}
                alt="Front Document"
                style={{ width: 200, height: '100%', objectFit: 'contain' }}
              />
            </Box>
            <ButtonApp
              text="Tomar foto nuevamente"
              disabled={status === 'pending'}
              variant="outlined"
              marginTop={20}
              onClick={() => {
                setDocumentStep('front-image');
                setFrontImage(null);
              }}
            />

            <Box
              width="100%"
              height="148px"
              borderRadius="8px"
              border="1px solid #6F778240"
              padding="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={backImage.data_url}
                alt="Front Document"
                style={{ width: 200, height: '100%', objectFit: 'contain' }}
              />
            </Box>
            <ButtonApp
              disabled={status === 'pending'}
              text="Tomar foto nuevamente"
              variant="outlined"
              marginTop={20}
              onClick={() => {
                setDocumentStep('back-image');
                setBackImage(null);
              }}
            />
          </Box>
          <ButtonApp
            text="Continuar"
            onClick={onConfirmImgs}
            loading={status === 'pending'}
          />
        </>
      );
    }
  };

  return (
    <Box
      display="flex"
      padding="25px"
      flexDirection="column"
      minHeight="100vh"
      style={{ overflowY: 'auto' }}
    >
      <ProgressHeader
        onBack={null}
        title={me?.steps?.['step_1'].title}
        progress={me?.steps?.['step_1'].progress}
        steps={me?.steps}
      />
      {renderDocumentSteps()}
    </Box>
  );
};

export default DocumentInstructions;
