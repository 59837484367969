import { appEndpointWithToken } from '../utils/axios';

const signContract = async (token) => {
  try {
    const { data } = await appEndpointWithToken().post('/verify-otp', token);
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export default signContract;
