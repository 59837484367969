import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ButtonApp from './ButtonApp';
import CloseIcon from '@material-ui/icons/Close';
import ImageUploading from 'react-images-uploading';
import frontPlaceHolder from '../assets/images/front3.png';
import backPlaceHolder from '../assets/images/back3.png';
import Webcam from 'react-webcam';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontFamily: 'App-semi-bold',
    color: '#fff',
    // flexGrow: 1,
    width: 250,
  },
  image: {
    width: '100%',
    height: 240,
    objectFit: 'contain',
  },
}));

const Upload = ({
  image,
  setImage,
  maxFiles = 1,
  callback,
  isWebCamActive = false,
  includeMobileCam = false,
  setWebcamPic,
  onClose,
  description,
  includePlaholder,
  inputCapture
}) => {
  const classes = useStyles();
  const webcamRef = React.useRef(null);

  const onChange = (image) => {
    setImage(image[0]);

    if (callback) {
      callback();
    }
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setWebcamPic(imageSrc);
    callback();
  }, [webcamRef]);

  const onCapture = (dataUri) => {
    setWebcamPic(dataUri);
    callback();
  };

  if (image) {
    return <></>;
  }

  return (
    <Box
      position="absolute"
      style={{ backgroundColor: '#000000C1' }}
      width="100%"
      minHeight="100vh"
      top={0}
      left={0}
      zIndex={10}
      padding="20px"
      display="flex"
      flexDirection="column"
    >
      <CloseIcon
        htmlColor="#fff"
        style={{ position: 'absolute', top: 24, right: 26, cursor: 'pointer' }}
        onClick={onClose}
      />

      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        width="100%"
        margin="0 auto"
      >
        {isWebCamActive && (
          <>
            <Webcam
              height={400}
              width={400}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              style={{
                borderRadius: 5,
              }}
              videoConstraints={{ facingMode: 'user' }}
            />
            <Typography className={classes.title} align="center">
              {description}
            </Typography>
          </>
        )}

        {includeMobileCam && (
          <Camera
            onTakePhoto={(dataUri) => {
              onCapture(dataUri);
            }}
            idealFacingMode="user"
          />
        )}

        {includePlaholder === 'back' && (
          <img src={backPlaceHolder} className={classes.image} alt="404" />
        )}
        {includePlaholder === 'front' && (
          <img src={frontPlaceHolder} className={classes.image} alt="404" />
        )}
      </Box>

      {includeMobileCam ? (
        <></>
      ) : (
        <ImageUploading
          // multiple
          value={image}
          onChange={onChange}
          maxNumber={maxFiles}
          dataURLKey="data_url"
          inputProps={{capture: inputCapture}}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <ButtonApp
              text="Tomar Foto"
              onClick={isWebCamActive ? capture : onImageUpload}
              {...dragProps}
            />
          )}
        </ImageUploading>
      )}
    </Box>
  );
};

export default Upload;
