import { Typography } from '@material-ui/core';
import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const PageHeader = ({ onClick }) => {
  return (
    <button
      style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}
      onClick={onClick}
    >
      <ArrowBackIosIcon htmlColor="#273240" style={{ fontSize: 16 }} />
      <Typography style={{ fontFamily: 'App-font-medium', fontSize: 12 }}>
        Volver
      </Typography>
    </button>
  );
};

export default PageHeader;
