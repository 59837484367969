import moment from 'moment';
export const handleOnChangeInput = (field, setState, type) => {
  return (event) => {

    const {value} = event.target;
    let zoneId = null;
    if(event.target.selectedOptions) {
      zoneId = event.target.selectedOptions[0].getAttribute('data-zone-id');
    }

    if (type.trim() === 'numeric') {
      if (value.length === 0) {
        setState((prevState) => ({
          ...prevState,
          [field]: ''
        }));
      }
      if (value.match(/[0-9]$/g)) {
        setState((prevState) => ({
          ...prevState,
          [field]: value
        }));
      }
    } else {
      let groupId = 0;
      if (field.includes("[")) {
        groupId = field?.split('_')[1]
            .split('[')[1]
            .split(']')[0] || 0;
      }

      if (zoneId != null) {
        setState((prevState) => ({
          ...prevState,
          [field]: value,
          ['special_' + groupId]: {'zone': zoneId, 'field': field, 'group_id': groupId},
          ['zoneId']: zoneId
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [field]: value
        }));
      }

    }
  };
};

export const handleChangeCheckbox = (field, setState) => (event) => {
  const { checked } = event.target;

  console.log('handleChangeCheckbox', event.target.checked, field, checked);

  setState((prevState) => ({
    ...prevState,
    [field]: checked,
  }));
};

export const handleChangeCode = (field, setState) => (code) => {
  setState((prevState) => ({
    ...prevState,
    [field]: code,
  }));
};
export const handleOnChangeInputCurrency = (field, setState) => (event) => {
  const { value } = event.target;

  setState((prevState) => ({
    ...prevState,
    [field]: value,
  }));
};
export const handleOnChangePhoneInput = (field, setState) => (phone) => {
  setState((prevState) => ({
    ...prevState,
    [field]: phone,
  }));
};

export const handleOnChangeFile = (field, setState) => (file) => {
  setState((prevState) => ({
    ...prevState,
    [field]: file,
  }));
};

export const handleDeleteFile = (field, setState) => () => {
  setState((prevState) => ({
    ...prevState,
    [field]: null,
  }));
};

export const handleBooleanOnChange = (field, setState) => (option) => {
  setState((prevState) => ({
    ...prevState,
    [field]: option,
  }));
};

export const handleOnChangeDate = (field, setState) => (date) => {
  setState((prevState) => ({
    ...prevState,
    [field]: moment(date).format('YYYY-MM-DD'),
  }));
};

export const setDefaultState = (comps, state, setState, otherObjs = {}) => {
  const preRenderState = {};
  for (const comp of comps) {
    if (comp?.value) {
      preRenderState[comp.name] = comp.value;
    }
  }

  setState({ ...state, ...preRenderState, ...otherObjs });
};

export const setNestedComponentsDefaultState = (comps, setState) => {
  const preRenderState = {};
  for (const comp of comps) {
    if (comp?.value) {
      preRenderState[comp.name] = comp.value;
    }
  }
  setState((prevState) => ({
    ...prevState,
    ...preRenderState,
  }));
};

const replaceStateKeys = (componentsToClone, components, position) => {
  const componentsKeysReplaced = [];
  components.forEach((component) => {
    const nestedOptions = [];
    if (componentsToClone.includes(component?.id?.toString())) {
      if (component?.options?.length > 0) {
        component?.options?.forEach((option, index) => {
          const fields = option?.fields;
          option?.fields?.forEach((nestedComponent, indexComponent) => {
            if (componentsToClone.includes(nestedComponent.id.toString())) {
              fields[indexComponent] = {
                ...nestedComponent,
                name: `${nestedComponent.name?.split('[')[0]}[${
                    parseInt(position) + 1
                }]`,
                order: parseInt(fields.order)
              };
            }
          });
          nestedOptions[index] = { ...option, fields };
        });
      }

      // console.log('replaceStateKeys: ', component, component.order, parseInt(position), component.order + (100 * (parseInt(position) + 1)));

      componentsKeysReplaced.push({
        ...component,
        name: `${component.name?.split('[')[0]}[${parseInt(position) + 1}]`,
        options: nestedOptions,
        order: parseInt(component.order) + (100 * (parseInt(position) + 1))
      });
    }
  });

  return componentsKeysReplaced;
};

export const onChangeCloneStateKeys =
    (components, setComponents) => (componentsToClone) => {
      const componentsCopy = components.slice(0, components.length - 1);
      const positionKey =
          componentsCopy[componentsCopy.length - 1]?.name
              ?.split('_')[1]
              .split('[')[1]
              .split(']')[0] || 0;

      let componentsChanged = replaceStateKeys(
          componentsToClone,
          componentsCopy,
          positionKey
      );

      const arr1 = [...new Map(componentsChanged.map(item => [item['name'], item])).values()]

      componentsChanged = arr1;

      componentsChanged = componentsChanged.slice(0);
      componentsChanged.sort(function(a,b) {
        return a.order - b.order;
      });

      setComponents(componentsChanged);
    };
