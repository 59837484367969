import {Box, Tooltip, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from "@material-ui/icons/Help";

function InputBox ({
  value,
  onChange,
  type,
  disable,
  loading,
  isVerified,
  id,
  label,
  placeHolder,
  inputStyles,
  labelStyles,
  capitalize,
  uppercase,
  containerStyles,
  maxLength,
  minLenght,
  error,
  suffix,
  hint,
  disabledMargin = true,
  helperText
}) {

  const [openTooltip, setOpenTooltip] = useState(false);

  return (<Wrapper
      style={
        disabledMargin
            ? { ...containerStyles, marginBottom: '25px' }
            : { ...containerStyles }
      }
  >
    <Box
        display="flex"
    >
      <Input
          capitalize={capitalize}
          uppercase={uppercase}
          value={value || ''}
          onChange={onChange}
          id={id}
          minLenght={minLenght}
          maxLength={maxLength}
          placeholder={placeHolder}
          type={type}
          disabled={disable}
          style={inputStyles}
          autoComplete="off"
      />

      {label && (
          <Label htmlFor={id} style={labelStyles} value={value}>
            {label}
          </Label>
      )}
      <Typography
          style={{ color: 'red', fontSize: 10, fontFamily: 'App-font-medium' }}
      >
        {error}
      </Typography>


      {
        helperText
            ?
            <BootstrapTooltip
                title={helperText}
                open={openTooltip}
            >
              <HelpIcon
                  fontSize="default"
                  htmlColor="#00e39c"
                  style={{ marginLeft: 10, cursor: 'pointer', outline: 0 }}
                  onClick={() => setOpenTooltip(!openTooltip)}
              />
            </BootstrapTooltip>
            : ''
      }

    </Box>

    {suffix && (
        <Box
            display="flex"
            alignItems="center"
            position="absolute"
            top="11px"
            right="15px"
        >
          <Typography style={{ color: '#27324080', fontSize: 14 }}>
            {suffix}
          </Typography>
        </Box>
    )}

    {isVerified && (
        <Box
            display="flex"
            alignItems="center"
            position="absolute"
            top="11px"
            right="15px"
        >
          <CheckIcon
              fontSize="small"
              htmlColor="#00e39c"
              style={{ marginRight: 5 }}
          />
          <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontFamily: 'App-semi-bold',
                color: '#00e39c',
              }}
          >
            Verificado
          </Typography>
        </Box>
    )}

    {hint && (
      <Typography
        color="textSecondary"
        style={{
          marginTop: '.5em',
          fontSize: 12,
          fontFamily: 'App-semi-bold',
          color: '#00e39c',
        }}
        dangerouslySetInnerHTML={{__html: hint}}>
      </Typography>
    )}
  </Wrapper>);
}


export function BootstrapTooltip(props) {
  const { title, open, handleTooltipClose } = props;
  return (
      <Tooltip
          onClose={handleTooltipClose}
          open={open}
          arrow
          title={title}
          placement="top-start"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          {...props}
      />
  );
};

export const Input = styled.input`
  font-size: 14px;
  padding: 10px 15px 10px 15px;
  display: block;
  color: #273240;
  width: 100%;
  border: none;
  font-family: 'App-font-medium';
  border-bottom: 1px solid #6f778280;
  outline: none;
  ${(props) => props.capitalize && `text-transform: capitalize`};

  ${(props) => props.uppercase && `text-transform: uppercase`};
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #6f7782;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  & input:focus + label {
    top: -15px;
  }
`;

export const Label = styled.label`
  color: #6f7782;
  font-size: 12px;
  font-family: 'App-regular';
  position: absolute;
  pointer-events: none;
  left: ${(props) => (props.isCurrency ? '12px' : '5px')};
  top: 10px;
  transition: 0.2s ease all;
  ${(props) => props.value && `top: -15px`};
`;

export default InputBox;
