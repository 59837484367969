import { Box } from '@material-ui/core';
import { getConfig } from '../../config/config';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ProgressHeader from '../../Components/ProgressHeader';
import useGetMe from '../../hooks/useGetMe';
import { Renderer } from '../../config/Renderer';
import { setDefaultState } from '../../config/handlers';
import ButtonApp from '../../Components/ButtonApp';
import { useHistory } from 'react-router-dom';
import onSaveStep from '../../services/saveStep';
import { toast } from 'react-toastify';
import LoadingStep from '../../Components/LoadingStep';
import { Context } from '../../Context';
import useGetHelpData from '../../hooks/useGetHelpData';

const Documentation = () => {
  const { me, loadingMe } = useGetMe();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState('idle');
  const { desktop_img } = useGetHelpData(
    me?.user?.profile,
    me?.steps?.step_3?.name
  );

  const history = useHistory();
  const { setDesktopImg } = useContext(Context);

  useEffect(() => {
    if (desktop_img) {
      setDesktopImg(desktop_img);
    }
  }, [desktop_img]);

  useEffect(() => {
    if (me) {
      if (me?.steps?.step_3?.order === 3) {
        setDefaultState(me?.steps?.step_3?.body, state, setState);
      }
    }
  }, [me]);

  const config = useMemo(() => {
    if (me) {
      return getConfig({
        state,
        setState,
        componentsFromServer: me?.steps?.step_3?.body,
        isLoading: loading === 'pending',
      });
    }
  }, [state, me, loading]);

  const saveStep = () => {
    const stateKeys = Object.keys(state);
    let counter = 0;
    let newFile = 0;
    stateKeys.forEach((key) => {
      if (state[key]?.['size']) {
        newFile = newFile + 1;
      }
      if (state[key]?.['original']) {
        counter = counter + 1;
      }
    });

    console.log(stateKeys, state, counter);

    if (counter === 0 && newFile === 0) {
      toast.error(
        'Para que los cambios se guarden debe ajuntar un archivo.'
      );
      return;
    }

    if (counter === 1) {
      me.user.profile === 'Persona Natural'
          ? history.push('/company_information')
          : history.push('/legal_representative');
      return;
    }

    setLoading('pending');
    const imgData = new FormData();

    stateKeys.forEach((key) => {
      imgData.append(key, state[key]);
    });

    imgData.append('view', 'documentation');
    onSaveStep(imgData, true)
      .then((data) => {
        setLoading('resolved');
        me.user.profile === 'Persona Natural'
            ? history.push('/company_information')
            : history.push('/legal_representative');
      })
      .catch((err) => {
        const errorKeys = Object.keys(err.response?.data?.errors);

        let message = '';

        for (const key of errorKeys) {
          message += `⋅ ${key} - ${err.response?.data?.errors[key][0]}
            `;
        }
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading('rejected');
      });
  };

  if (loadingMe) {
    return <LoadingStep />;
  }

  return (
    <Box
      padding="25px"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      style={{ overflowY: 'auto' }}
    >
      <ProgressHeader
        title="Documentos"
        progress={10}
        steps={me?.steps}
        onBack={() => history.push('/selfie')}
      />
      <Box flexGrow={1} marginTop="30px">
        <Renderer config={config} />
      </Box>
      <ButtonApp
        text="Continuar"
        loading={loading === 'pending'}
        onClick={() => saveStep()}
      />
    </Box>
  );
};

export default Documentation;
