export const converFromByteToMb = (bytes, decimals = 2) => {
  return (bytes / 1000000).toFixed(decimals);
};

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};


export const getQueryParams = () => {
  const params = {};
  const search = window.location.search;
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  let match;

  while ((match = regex.exec(search))) {
    params[match[1]] = match[2];
  }

  return params;
}
