import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ButtonApp from '../../Components/ButtonApp';
import PageHeader from '../../Components/PageHeader';
import PhoneInputBox from '../../Components/PhoneInput';
import firebase from 'firebase';
import {signInWithEmail, signInWithPhoneNumber} from '../../firebase';
import { useHistory } from 'react-router-dom';
import InputBox from "../../Components/InputBox";
import useInput from "../../hooks/useInput";
import sendEmailOTP from "../../services/sendEmailOTP";
import {toast} from "react-toastify";
import validateUser from "../../services/validateUser";
import registerPhoneOtp from "../../services/registerPhoneOtp";
import loginPhoneOtp from "../../services/loginPhoneOtp";

const Otp = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userEmail = useInput('', isSubmitting);
  const [loading, setLoading] = useState('idle');
  const history = useHistory();
  let user = {name: ''};
  const onSendVerificationMessage = async () => {

    try {
      user = {
        phoneNumber,
        companyName: '',
        documentId: '',
        documentType: '',
        nit: '',
        profile: '',
        ciiu: '',
        email: userEmail.value,
        terms: '',
        referral_code: '',
        isSignup: false,
      };

      localStorage.setItem('user-data', JSON.stringify(user));

      await signInPhone();

    } catch (error) {
      // console.log(error);
    }
  };

  const signInPhone = async () => {

    loginPhoneOtp(phoneNumber.slice(2))
        .then((res) => {
          toast.success(`Código de verificación enviado al celular`);
          history.push('/verify?action=signIn', {
            phoneNumber,
            isSignup: false,
          });
        })
        .catch((err) => {
            let message = err;
            if ('errors' in err) {
                message = Object.keys(err.errors)
                    .map((key) => `${err.errors[key]}`)
                    .join('\n');
            }
            toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

  }

  const signInEmail = async () => {
    const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-otp', {
      size: 'invisible',
    });

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      // url: 'https://unete.paymovil.co/verify-email',
      url: process.env.REACT_APP_VERIFY_EMAIL_URL,
      // This must be true.
      handleCodeInApp: true
    };

    try {
      await signInWithEmail(
          user.email,
          actionCodeSettings
      );
      toast.success(`Se ha enviado un link al correo electrónico para iniciar sesión`, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      // console.log(error);
    }
  }


    return (
    <Box
      padding="25px"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      style={{ overflowY: 'auto' }}
    >
      <div id="recaptcha-otp" style={{ visibility: 'hidden' }} />
      <PageHeader onClick={() => history.push('/')} />

      <Box display="flex" flexDirection="column" flexGrow="1">
        <Typography style={{ fontSize: 20, fontFamily: 'App-bold' }}>
          Ingresa tu número de teléfono y correo electrónico
        </Typography>
        <Typography style={{ marginTop: 12, fontSize: 14, marginBottom: 40 }}>
          Por favor ingresa tu número de teléfono y correo electrónico registrados previamente para reanudar el proceso de
          vinculación.
        </Typography>
        <PhoneInputBox label="" value={phoneNumber} onChange={setPhoneNumber} />

      </Box>
      <ButtonApp
        text="Continuar"
        disabled={phoneNumber.length !== 12 || loading === 'pending'}
        onClick={onSendVerificationMessage}
        loading={loading === 'pending'}
      />
    </Box>
  );
};

export default Otp;
