import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ButtonApp from '../../Components/ButtonApp';
import LoadingStep from '../../Components/LoadingStep';
import ProgressHeader from '../../Components/ProgressHeader';
import { getConfig } from '../../config/config';
import { setDefaultState } from '../../config/handlers';
import { Renderer } from '../../config/Renderer';
import useGetMe from '../../hooks/useGetMe';
import onSaveStep from '../../services/saveStep';

const ShareCertification = () => {
  const { me, loadingMe } = useGetMe();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState('idle');

  const history = useHistory();

  useEffect(() => {
    if (me) {
      if (me?.steps?.step_8?.order === 8) {
        setDefaultState(me?.steps?.step_8?.body, state, setState);
      }
    }
  }, [me]);

  const saveStep = () => {
    setLoading('pending');
    const stateKeys = Object.keys(state);
    const imgData = new FormData();

    stateKeys.forEach((key) => {
      imgData.append(key, state[key]);
    });

    imgData.append('view', 'share_certification');
    onSaveStep(imgData, true)
      .then((data) => {
        setLoading('resolved');
        history.push('/unrelated_activities');
      })
      .catch((err) => {
        const errorKeys = Object.keys(err.response?.data?.errors);

        let message = '';

        for (const key of errorKeys) {
          message += `⋅ ${key} - ${err.response?.data?.errors[key][0]}
            `;
        }
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading('rejected');
      });
  };

  const config = useMemo(() => {
    if (me) {
      return getConfig({
        state,
        setState,
        componentsFromServer: me?.steps?.step_8?.body,
        isLoading: loading === 'pending',
      });
    }
  }, [state, me, loading]);

  if (loadingMe) {
    return <LoadingStep />;
  }

  return (
    <Box
      padding="25px"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      style={{ overflowY: 'auto' }}
    >
      <ProgressHeader
        title={me?.steps?.step_8?.title}
        progress={me?.steps?.step_8?.progress}
        onBack={() => history.push('/partners_and_shareholders')}
        steps={me?.steps}
      />
      <Box marginTop="30px" flexGrow={1}>
        <Renderer config={config} />
      </Box>
      <ButtonApp
        text="Continuar"
        loading={loading === 'pending'}
        onClick={() => saveStep()}
      />
    </Box>
  );
};

export default ShareCertification;
