import { appEndpointWithFiles } from '../utils/axios';

const uploadSelfie = async (selfie) => {
  const selfieData = new FormData();
  selfieData.append('selfie', selfie);

  try {
    const res = await appEndpointWithFiles().post('/save-selfie', selfieData);
    return res;
  } catch (error) {
    throw error;
  }
};

export default uploadSelfie;
