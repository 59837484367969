import React, { Fragment } from 'react';

const mapPropsToConfig = (config) => {
  const configWithProps = [];
  config.forEach((item) => {
    if (item.component) {
      const { component, ...props } = item;

      configWithProps.push({
        ...props,
        Component: component,
      });
    }
  });

  return configWithProps;
};

export const onOrderComponents = (components) =>
  components.sort((a, b) => {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    } else {
      return 0;
    }
  });

export const Renderer = ({ config, orderComponents = true }) => {
  if (!config) {
    throw new Error('You are calling Renderer with no config.');
  }

  const configWithProps = mapPropsToConfig(config);
  const renderComponents = (items) => {
    if (!orderComponents) {
      return items.map((item) => {
        const { Component, ...props } = item;

        return (
          <Fragment key={props.name}>
            <Component {...props} />
          </Fragment>
        );
      });
    }

    const componentsOrdered = onOrderComponents(items);

    return componentsOrdered.map((item) => {
      const { Component, ...props } = item;

      return (
        <Fragment key={props.name}>
          <Component {...props} />
        </Fragment>
      );
    });
  };

  return renderComponents(configWithProps);
};
