import {
    InputBase,
    NativeSelect,
    Typography,
    withStyles,
} from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

let zone_id;
let test;

const BootstrapInputFillled = withStyles((theme, value) => ({
    root: {
        width: '100%',

        height: 40,
    },
    input: {
        borderRadius: 120,
        color: '#6F778280',
        position: 'relative',
        backgroundColor: '#f8f8f9',
        border: '1px solid #f8f8f9',
        fontSize: 13,
        height: 20,
        padding: '7px 26px 7px 18px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            'App-regular',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#f8f8f9',
            backgroundColor: '#f8f8f9',
        },
    },
}))(InputBase);

const BootstrapInputOutlined = withStyles((theme) => ({
    root: {
        width: '100%',
        height: 40,
        '& svg': {
            right: 10,
        },
    },
    input: {
        color: '#273240',
        borderRadius: 120,
        position: 'relative',
        backgroundColor: 'transparent',
        border: '1px solid #6F778280',
        fontSize: 13,
        height: 25,
        padding: '7px 26px 7px 18px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'App-regular',
            'App-font-medium',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 120,
            borderColor: '#6F778280',
            backgroundColor: 'transparent',
        },
        '&::placeholder': {
            color: '#6F778280',
        },
    },
}))(InputBase);

const onchageZone = (event) => {

    // console.log('entra al onchange: ');
    zone_id = event.target.value;

    // console.log(zone_id);

}


function changeZone(event) {
    zone_id = event.target.value;
}

var SelectBox = ({
                     id,
                     value,
                     onChange,
                     options,
                     relatedData,
                     outlined = true,
                     selectKey,
                     selectValue = 'value',
                     selectText = 'text',
                     label,
                     withMargin = true,
                     disable
                 }) => {

    if(relatedData === 'zones'){
        test = <>
            <Typography
                style={{ fontFamily: 'App-semi-bold', fontSize: 12, marginBottom: 10 }}
            >
                {label}
            </Typography>

            <NativeSelect
                disabled={disable}
                selectMenu={{ background: 'blue' }}
                id={id}
                input={
                    outlined ? (
                        <BootstrapInputOutlined value={value} />
                    ) : (
                        <BootstrapInputFillled />
                    )
                }
                onChange={onChange}
                value={value}
                IconComponent={ExpandMoreIcon}
                style={{
                    textTransform: 'capitalize',
                    marginBottom: withMargin ? 25 : 0,
                }}
                defaultValue={`Seleccione una opción.`}
            >
                <option style={{ color: '#6F778280' }} value="0">
                    Seleccione una opción.
                </option>

                {options?.map((option, index) => (
                    <option
                        value={option.name}
                        key={selectKey ? option[selectKey] : index}
                        data-zone-id={option.zone_id}
                        style={{ textTransform: 'capitalize', color: '#273240' }}
                    >
                        {option.name}
                    </option>
                ))}
            </NativeSelect>
        </>;
    } else if(relatedData === 'cities') {

        test = <>
            <Typography
                style={{ fontFamily: 'App-semi-bold', fontSize: 12, marginBottom: 10 }}
            >
                {label}
            </Typography>

            <NativeSelect
                disabled={disable}
                selectMenu={{ background: 'blue' }}
                id={id}
                input={
                    outlined ? (
                        <BootstrapInputOutlined value={value} />
                    ) : (
                        <BootstrapInputFillled />
                    )
                }
                onChange={onChange}
                value={value}
                IconComponent={ExpandMoreIcon}
                style={{
                    textTransform: 'capitalize',
                    marginBottom: withMargin ? 25 : 0,
                }}
                defaultValue={`Seleccione una opción.`}
            >
                <option style={{ color: '#6F778280' }} value="0">
                    Seleccione una opción.
                </option>

                {options?.map((option, index) => (
                    <option
                        value={option.city}
                        data-zone-id={option.parent_zone_id}
                        key={selectKey ? option[selectKey] : index}
                        style={{textTransform: 'capitalize', color: '#273240'}}
                    >
                        {option.city}
                    </option>
                ))}

            </NativeSelect>
        </>;
    } else {
        test = <>
            <Typography
                style={{ fontFamily: 'App-semi-bold', fontSize: 12, marginBottom: 10 }}
            >
                {label}
            </Typography>

            <NativeSelect
                disabled={disable}
                selectMenu={{ background: 'blue' }}
                id={id}
                input={
                    outlined ? (
                        <BootstrapInputOutlined value={value} />
                    ) : (
                        <BootstrapInputFillled />
                    )
                }
                onChange={onChange}
                value={value}
                IconComponent={ExpandMoreIcon}
                style={{
                    textTransform: 'capitalize',
                    marginBottom: withMargin ? 25 : 0,
                }}
                defaultValue={`Seleccione una opción.`}
            >
                <option style={{ color: '#6F778280' }} value="0">
                    Seleccione una opción.
                </option>

                {typeof options?.[0] === 'string'
                    ? options.map((option, index) => (
                        <option
                            value={option}
                            key={index}
                            style={{ textTransform: 'capitalize', color: '#273240' }}
                        >
                            {option}
                        </option>
                    ))
                    : options?.map((option, index) => (
                        <option
                            value={option[selectValue]}
                            key={selectKey ? option[selectKey] : index}
                            style={{ textTransform: 'capitalize', color: '#273240' }}
                        >
                            {option[selectText]}
                        </option>
                    ))}
            </NativeSelect>
        </>;
    }
    return ( test );
};

export default SelectBox;
