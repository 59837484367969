import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Input, Label, Wrapper } from './InputBox';

const InputCurrencyBox = ({
  label,
  inputStyles,
  labelStyles,
  value,
  id,
  onChange,
  disable,
  minLenght,
  maxLength,
  placeHolder,
  disabledMargin = true,
  error,
  containerStyles,
  prefix = '$',
}) => {
  return (
    <Wrapper
      style={
        disabledMargin
          ? { ...containerStyles, margin: '25px 0' }
          : { ...containerStyles }
      }
    >
      {prefix && (
        <Box display="flex" alignItems="center" position="absolute" top="9px">
          <Typography style={{ color: '#27324080', fontSize: 14 }}>
            {prefix}
          </Typography>
        </Box>
      )}
      <Input
        value={
          value
            ? new Intl.NumberFormat('de-DE').format(
                value.toString().replace(/[\D\s\._\-]+/g, '')
              )
            : value
        }
        onChange={onChange}
        id={id}
        minLenght={minLenght}
        maxLength={maxLength}
        placeholder={placeHolder}
        disabled={disable}
        style={inputStyles}
      />
      {label && (
        <Label htmlFor={id} style={labelStyles} value={value} isCurrency>
          {label}
        </Label>
      )}
      <Typography
        style={{ color: 'red', fontSize: 10, fontFamily: 'App-font-medium' }}
      >
        {error}
      </Typography>
    </Wrapper>
  );
};

export default InputCurrencyBox;
