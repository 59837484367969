import { createGlobalStyle } from 'styled-components';
import Bold from './assets/fonts/Montserrat-Bold.ttf';
import Regular from './assets/fonts/Montserrat-Regular.ttf';
import Semibold from './assets/fonts/Montserrat-SemiBold.ttf';
import Medium from './assets/fonts/Montserrat-Medium.ttf';

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'App-bold';
    src: url(${Bold}) format('opentype');
  }
  @font-face {
    font-family: 'App-regular';
    src: url(${Regular}) format('opentype');
  }
  @font-face {
    font-family: 'App-semi-bold';
    src: url(${Semibold}) format('opentype');
  }
  @font-face {
    font-family: 'App-font-medium';
    src: url(${Medium}) format('opentype');
  }

  *, *:before, *:after {
    box-sizing: inherit
  }

  body {

    background: white;

    margin: 0 auto;
    overscroll-behavior: none;
    width: 100%;
    font-family: "Roboto";
  }

  ul, li, h1, h2, h3, p, button {
    margin: 0;
  }


  button {
    background: #ffffff00;
    border: 0;
    outline: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  ul {
    padding: 0;
  }
  
  a { 
    text-decoration: none;
    color: #393939
  }

  .MuiNativeSelect-icon {
      right: 10px;
  }

  .MuiButton-contained.Mui-disabled {
    color: #6F7782 !important;
    background-color: #6F778240 !important;
  }

  .react-tel-input .form-control:focus {
    box-shadow: unset !important;
  }

  .Toastify__toast {
    width: 312px;
    margin: 0 0 0 auto;
    font-family: 'App-regular';
    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .MuiLinearProgress-colorPrimary {
    background-color: #6F778241 !important;
  }

  .react-code-input {
    display: flex !important;
    width: 100% !important;
    justify-content: space-between;
  }
  .MuiNativeSelect-selectMenu {
    background: blue
  }

  #outer-circle {
    top: 107px !important
  }

  .Toastify__toast--success {
    background: #00e39c;
  }
`;

export default GlobalStyles;
