import { appEndpointWithFiles } from '../utils/axios';

const uploadDocumentId = async (frontImageId, backImageId) => {
  const documentsIdData = new FormData();
  documentsIdData.append('front_image', frontImageId);
  documentsIdData.append('back_image', backImageId);

  try {
    const res = await appEndpointWithFiles().post(
      '/save-document-id',
      documentsIdData
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default uploadDocumentId;
