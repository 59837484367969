import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import InputBox from './InputBox';
import RedeemIcon from '@material-ui/icons/Redeem';

const useStyles = makeStyles(() => ({
  refferText: {
    fontSize: 12,
    color: '#6F7782',
    fontFamily: 'App-font-medium',
    textDecoration: 'underline',
  },
}));
const RefferCode = ({
  handleRefferCode,
  showRefferCode,
  refferCode,
  onChangeRefferCode,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        marginTop="18px"
        style={{ cursor: 'pointer' }}
        onClick={handleRefferCode}
      >
        <RedeemIcon
          htmlColor="#6F7782"
          fontSize="small"
          style={{ marginRight: 5 }}
        />
        <Typography className={classes.refferText}>
          Si tiene un código de referido, ingréselo aquí.
        </Typography>
      </Box>

      {showRefferCode && (
        <Box marginTop="18px">
          <Typography style={{ fontFamily: 'App-semi-bold', fontSize: 12 }}>
            Ingresa el código para redimirlo
          </Typography>

          <InputBox
            capitalize
            inputStyles={{ fontSize: 14 }}
            uppercase
            isVerified={false}
            value={refferCode}
            onChange={onChangeRefferCode}
            maxLength={6}
            disabledMargin={false}
          />
        </Box>
      )}
    </>
  );
};

export default RefferCode;
