import { Box, Typography } from '@material-ui/core';
import React from 'react';

const DescriptionItem = ({ step, description }) => {
  return (
    <Box width="100%" display="flex" alignItems="center" marginBottom="16px">
      <Box
        minWidth="22px"
        minHeight="22px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginRight="16px"
        borderRadius="50%"
        style={{ backgroundColor: '#5D62F1' }}
      >
        <Typography
          style={{ fontSize: 11, fontFamily: 'App-font-medium', color: '#fff' }}
        >
          {step}
        </Typography>
      </Box>
      <Typography style={{ fontSize: 12 }}>{description}</Typography>
    </Box>
  );
};

export default DescriptionItem;
