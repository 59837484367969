import { appEndpointWithToken } from '../utils/axios';

const sendOTP = async () => {
  try {
    const res = await appEndpointWithToken().post('/send-otp');
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export default sendOTP;
