import {appEndpoint} from '../utils/axios';

const registerUser = async (
  profile,
  first_name,
  second_name,
  first_last_name,
  second_last_name,
  companyName,
  documentId,
  expedition_date,
  nit,
  ciiu,
  refferCode,
  phone,
  email,
  terms,
  sign_in_type,
  referral_code,
  document_type,
  otp,
  isClaro
) => {
  const signupKeys = {
    ciiu,
    first_name,
    second_name,
    first_last_name,
    second_last_name,
    expedition_date,
    phone,
    email,
    terms,
    profile,
    referral_code,
    sign_in_type,
    otp,
    is_claro_ref: isClaro ? 1 : 0
  };

  if (profile === 'natural') {
    signupKeys.document_id = documentId;
    signupKeys.document_type = document_type;
  }

  if (profile === 'legal') {
    signupKeys.company_name = companyName;
    signupKeys.nit = nit;
  }

  try {
    const res = await appEndpoint().post('/register', signupKeys);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export default registerUser;
