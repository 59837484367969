import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import { es } from 'date-fns/locale';
import 'moment/min/locales';

moment.locale('es', {
  months:
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_'
    ),
  monthsShort:
    'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
});

function InputDate({
  value = null,
  onChange,
  label,
  disableFuture = true,
  minYear: maxYear,
  disable,
}) {
  const minDate = moment(`01/01/${new Date().getFullYear()}`)
    .subtract(maxYear, 'years')
    .calendar();
  if (value === null || value === undefined){
    value = '1900-01-01';
  }
  let format_value = moment(value).add(1,'days').format('YYYY-MM-DD');
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <KeyboardDatePicker
        style={{ width: '100%', marginBottom: 25 }}
        value={format_value}
        onChange={onChange}
        label={label}
        disabled={disable}
        keyboardIcon={() => <> </>}
        placeholder="dd/mm/yyyy"
        maxDate={maxYear ? minDate : Date()}
        disableFuture={disableFuture}
        format="dd/MM/yyyy"
        icon={<CalendarTodayIcon />}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default InputDate;
