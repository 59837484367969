import React, {useContext} from 'react';
import {BrowserRouter, Redirect, Route, Router, Switch, useLocation,} from 'react-router-dom';
import AppLayout from '../Components/Layout/AppLayout';

import BankAccount from '../Pages/BankAccount/BankAccount';
import CompanyInformation from '../Pages/CompanyInformation/CompanyInformation';
import Contract from '../Pages/Contract/Contract';
import CreateAccount from '../Pages/CreateAccount/CreateAccount';
import Documentation from '../Pages/Documentation/Documentation';
import DocumentInstructions from '../Pages/DocumentsInstructions/DocumentsInstructions';
import FinancialInformation from '../Pages/FinancialInfomation/FinancialInfomation';
import Home from '../Pages/Home/Home';
import LegalRepresentative from '../Pages/LegalRepresentative/LegalRepresentative';
import Otp from '../Pages/OTP/Otp';
import PartnersAndShareholders from '../Pages/PartnersAndShareholders/PartnersAndShareholders';
import Selfie from '../Pages/Selfie/Selfie';
import ShareCertification from '../Pages/ShareCertification/ShareCertificaction';
import SuccessScreen from '../Pages/SuccessScreen';
import VerifyPhone from '../Pages/VerifyPhone/VerifyPhone';
import VerifyEmail from '../Pages/VerifyEmail/VerifyEmail';
import WrongRequest from '../Pages/WrongRequest';

import history from './history';
import UnrelatedActivities from "../Pages/UnrelatedActivities/UnrelatedActivities";
import {Context} from "../Context";

function NoMatch() {
    let location = useLocation();

    return (
        <div>
            <h3>
                No Found for <code>{location.pathname}</code>
            </h3>
        </div>
    );
}

function PrivateRoute({auth, formCompleted, children, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) => {
                if (auth && !formCompleted) {
                    return children;
                } else if (auth && formCompleted) {
                    return <SuccessScreen/>;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {from: location},
                            }}
                        />
                    );
                }
            }}
        />
    );
}

function AuthRoute({auth, loadingAuth, children, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) => {
                if (auth) {
                    return <WrongRequest default auth={auth}/>;
                }
                return children;
            }}
        />
    );
}

const AppRouter = ({auth, formCompleted}) => {
    const {isClaro} = useContext(Context);

    return (
        <Router history={history}>
            <AppLayout>
                <BrowserRouter>

                    <Switch>
                        <AuthRoute path="/" exact auth={auth}>
                            <Home/>
                        </AuthRoute>
                        <AuthRoute path="/signup" exact auth={auth}>
                            <CreateAccount/>
                        </AuthRoute>

                        <AuthRoute path="/otp" exact auth={auth}>
                            <Otp/>
                        </AuthRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/document_id"
                            exact
                            auth={auth}
                        >
                            <DocumentInstructions/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/unrelated_activities"
                            exact
                            auth={auth}
                        >
                            <UnrelatedActivities/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/legal_representative"
                            exact
                            auth={auth}
                        >
                            <LegalRepresentative/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/financial_information"
                            exact
                            auth={auth}
                        >
                            <FinancialInformation/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/partners_and_shareholders"
                            exact
                            auth={auth}
                        >
                            <PartnersAndShareholders/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/bank_account"
                            exact
                            auth={auth}
                        >
                            <BankAccount/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/view_signature"
                            exact
                            auth={auth}
                        >
                            <Contract/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/selfie"
                            exact
                            auth={auth}
                        >
                            <Selfie/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/documentation"
                            exact
                            auth={auth}
                        >
                            <Documentation/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/company_information"
                            exact
                            auth={auth}
                        >
                            <CompanyInformation/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/share_certification"
                            exact
                            auth={auth}
                        >
                            <ShareCertification/>
                        </PrivateRoute>
                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/otp"
                            exact
                            auth={auth}
                        >
                            <Otp/>
                        </PrivateRoute>

                        <PrivateRoute
                            formCompleted={formCompleted}
                            path="/submitted"
                            exact
                            auth={auth}
                        >
                            <SuccessScreen/>
                        </PrivateRoute>
                        <Route path="/verify" exact>
                            <VerifyPhone/>
                        </Route>

                        <Route path="/verify-email" exact>
                            <VerifyEmail/>
                        </Route>
                        <Route path="*">
                            <NoMatch/>
                        </Route>
                    </Switch>

                </BrowserRouter>
                {!isClaro && <a href="https://api.whatsapp.com/send?phone=5719199109" className="float " target="_blank"
                                title="WhatsApp">
                    <i className="fa fa-whatsapp my-float"></i>
                </a>}
            </AppLayout>
        </Router>
    );
};

export default AppRouter;
