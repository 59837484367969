import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from './Context';
import AppRouter from './router/AppRouter';
import getMe from './services/getMe';
import { getQueryParams } from "./utils/utils";

const App = () => {
  const {auth, setAuth, formCompleted, setFormCompleted, checkCompletedStep, setIsClaro} =
    useContext(Context);

  const [loading, setLoading] = useState(true);
  const params = getQueryParams()

  let token = null;
  console.log('start app.js');
  useEffect(() => {
    token = sessionStorage.getItem('pay-mobile-token');
    const isClaro = localStorage.getItem('isClaro')

    if (params.claro || isClaro) {
      setIsClaro(true)
      localStorage.setItem('isClaro', params.claro)
    }

    if (token != null) {
      if (checkCompletedStep) {
        getMe().then(({data}) => {
          const stepsKeys = Object.keys(data.steps);
          const {user: {request_id: {is_claro_ref}}} = data
          console.log(is_claro_ref)
          setIsClaro(Boolean(is_claro_ref))
          if (
            data.steps[stepsKeys[stepsKeys.length - 1]]?.status?.name ===
            'complete'
          ) {
            setFormCompleted(true);
          }
        });
      }

      setAuth(true);
      setLoading(false);
    } else {
      setLoading(false);
    }


    /*onAuthStateChanged((user) => {
      console.log('onAuthStateChanged app.js');

      if (user) {
        console.log('App onAuthStateChanged: ', user);
        user.getIdToken().then((token) => {
          sessionStorage.setItem('pay-mobile-token', token);
          sessionStorage.setItem('pay-mobile-refreshToken', user.refreshToken);
        });

        if (checkCompletedStep) {
          getMe().then(({ data }) => {
            const stepsKeys = Object.keys(data.steps);
            if (
              data.steps[stepsKeys[stepsKeys.length - 1]]?.status?.name ===
              'complete'
            ) {
              setFormCompleted(true);
            }
          });
        }

        setAuth(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });*/
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <Box
        minHeight="100vh"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography
          style={{fontSize: 22, fontFamily: 'App-bold', color: '#5d62f1'}}
          align="center"
        >
          Cargando
        </Typography>
        <CircularProgress
          style={{color: '#5d62f1', marginTop: 10}}
          size="30px"
        />
      </Box>
    );
  }

  return <AppRouter auth={auth} formCompleted={formCompleted}/>;
};

export default App;
