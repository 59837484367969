import { Box, Checkbox, makeStyles, Tooltip, Typography, } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ButtonApp from '../../Components/ButtonApp';
import ModalApp from '../../Components/ModalApp';
import PersonIcon from '@material-ui/icons/Person';
import InputBox from '../../Components/InputBox';
import SelectBox from '../../Components/SelectBox';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import firebase from 'firebase';

import 'react-phone-input-2/lib/material.css';
import PageHeader from '../../Components/PageHeader';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import useInput from '../../hooks/useInput';
import useGetHelpData from '../../hooks/useGetHelpData';
import PhoneInputBox from '../../Components/PhoneInput';
import { toast } from 'react-toastify';
import { Context } from '../../Context';
import registerValidation from '../../services/registerValidation';
import { useHistory, useLocation } from 'react-router-dom';
import registerPhoneOtp from "../../services/registerPhoneOtp";

import 'date-fns';
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import 'moment/min/locales';

moment.locale('es', {
  months:
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_'
    ),
  monthsShort:
    'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
});


const useStyles = makeStyles((theme) => ({
  container: {
    padding: 25,
    minHeight: '100vh',
  },
  arrow: {
    color: theme.palette.common.black,
  },

  text: {
    fontFamily: 'App-bold',
    textDecoration: 'underline',
    fontSize: 12,
    display: 'inline',
  },
}));

export function BootstrapTooltip(props) {
  const {title, open, handleTooltipClose} = props;
  return (
    <Tooltip
      onClose={handleTooltipClose}
      open={open}
      arrow
      title={title}
      placement="top-start"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      {...props}
    />
  );
}

const CreateAccount = () => {
  const [open, setOpen] = useState(true);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [checked, setChecked] = useState(false);
  const [terms, setTerms] = useState(false);
  const location = useLocation();
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userEmail = useInput('', isSubmitting);
  const nit = useInput('', isSubmitting);
  const companyName = useInput('', isSubmitting);
  const name = useInput('', isSubmitting);
  const first_name = useInput('', isSubmitting);
  const first_last_name = useInput('', isSubmitting);
  const second_last_name = useInput('', isSubmitting);
  const second_name = useInput('', isSubmitting);
  const documentId = useInput('', isSubmitting);
  const [expeditionDate, setExpeditionDate] = useState('01-01-1900');
  const ciiu = useInput('', isSubmitting);
  const {accountType, referral_code} = location.state;
  const documentType = useInput('', isSubmitting);
  const helpData = useGetHelpData(accountType);
  const {setDesktopImg, isClaro} = useContext(Context);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let user = {name: ''};
  useEffect(() => {
    if (helpData?.desktop_img) {
      setDesktopImg(helpData?.desktop_img);
    }
  }, [helpData?.desktop_img]);

  let value = '1900-01-01';
  let format_date = moment(value).add(1, 'days').format('YYYY-MM-DD');


  const onSignupUser = async () => {
    const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
      size: 'invisible',
    });


    let expedition_date = moment(expeditionDate).subtract(1, 'days').format('YYYY-MM-DD');
    setExpeditionDate(expedition_date);
    if (accountType === 'Persona Jurídica') {
      if (!nit.value || !companyName.value || !phoneNumber) {
        return;
      }
      if (nit.value.length < 9) {
        nit.setError('Ingrese un NIT valido.');
        return;
      } else {
        nit.setError('');
      }
    } else if (accountType === 'Persona Natural') {
      if (!first_name.value || !first_last_name.value || !second_last_name.value || !documentId.value || !phoneNumber) {
        return;
      }
    } else if (documentId.value.length < 6) {
      documentId.setError('El documento tiene que tener minimo 6 digitos');
      return;
    }

    if (accountType === 'Persona Jurídica' && !ciiu.value) {
      // toast.error('Seleccione un CIU');
      return;
    }

    if (!userEmail.value.length) {
      userEmail.setError('Ingrese un correo electrónico');
      return;
    }

    try {
      setIsSubmitting(true);
      setLoading(true);

      await registerValidation(
        accountType === 'Persona Jurídica' ? 'legal' : 'natural',
        first_name.value,
        second_name.value,
        first_last_name.value,
        second_last_name.value,
        companyName.value,
        documentId.value,
        expedition_date,
        nit.value,
        ciiu.value,
        null,
        null,
        phoneNumber.slice(2),
        userEmail.value,
        checked,
        referral_code,
        documentType.value,
        isClaro
      );

      user = {
        phoneNumber,
        companyName: companyName.value,
        first_name: first_name.value,
        second_name: second_name.value,
        first_last_name: first_last_name.value,
        second_last_name: second_last_name.value,
        documentId: documentId.value,
        expedition_date: expedition_date,
        documentType: documentType.value,
        nit: nit.value,
        profile: accountType === 'Persona Jurídica' ? 'legal' : 'natural',
        ciiu: ciiu.value,
        email: userEmail.value,
        terms: checked,
        referral_code,
        isSignup: true,
      };

      localStorage.setItem('user-data', JSON.stringify(user));

      //await signInWithPhoneNumber(`+${phoneNumber}`, appVerifier);


      registerPhoneOtp(phoneNumber.slice(2))
        .then((res) => {
          toast.success(`Código de verificación enviado al celular exitosamente`);
          setLoading('resolved');
          history.push('/verify', {
            phoneNumber,
            companyName: companyName.value,
            first_name: first_name.value,
            second_name: second_name.value,
            first_last_name: first_last_name.value,
            second_last_name: second_last_name.value,
            documentId: documentId.value,
            expedition_date: expedition_date,
            documentType: documentType.value,
            nit: nit.value,
            profile: accountType === 'Persona Jurídica' ? 'legal' : 'natural',
            ciiu: ciiu.value,
            email: userEmail.value,
            terms: checked,
            referral_code,
            isSignup: true,
          });
          setLoading(false);

        })
        .catch((err) => {
          toast.error(err, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading('rejected');
        });

    } catch (error) {
      const errorKeys = Object.keys(error?.errors);

      let message = '';

      for (const key of errorKeys) {
        message += `⋅ ${key} - ${error?.errors[key]}
          `;
      }
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  return (
    <Box className={classes.container}>
      <div id="recaptcha" style={{visibility: 'hidden'}}/>
      <PageHeader onClick={() => history.push('/')}/>
      <Typography style={{fontFamily: 'App-bold', fontSize: 20}}>
        Crear Cuenta
      </Typography>

      <Typography style={{fontSize: 12, margin: '8px 0 20px'}}>
        Cree su cuenta para continuar con el proceso
      </Typography>
      <Box display="flex" alignItems="center" marginBottom="50px">
        <Box
          width="33px"
          height="33px"
          borderRadius="50%"
          style={{background: '#F7F7F7'}}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {accountType === 'Persona Jurídica' ? (
            <BusinessCenterIcon fontSize="small" htmlColor="#5d62f1"/>
          ) : (
            <PersonIcon fontSize="small" htmlColor="#5d62f1"/>
          )}
        </Box>
        <Typography
          style={{
            fontSize: 14,
            fontFamily: 'App-semi-bold',
            marginLeft: 10,
          }}
        >
          {accountType || 'Persona Natural'}
        </Typography>
      </Box>
      {accountType === 'Persona Jurídica' ? (
        <>
          <InputBox
            label="Nombre de la empresa"
            value={companyName.value}
            onChange={companyName.onChange}
            error={companyName.error}
            disabledMargin={false}
          />
          <InputBox
            label="NIT (incluir dígito de verificación)"
            disabledMargin={false}
            value={nit.value}
            maxLength={11}
            minLenght={9}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                nit.setValue('');
              }
              if (e.target.value.match(/^[0-9-/+]+$/g)) {
                nit.onChange(e);
              }
            }}
            containerStyles={{marginTop: 30}}
            error={nit.error}
          />
        </>
      ) : (
        <>
          <InputBox
            label="Primer nombre"
            value={first_name.value}
            disabledMargin={false}
            onChange={first_name.onChange}
            error={first_name.error}
          />
          <InputBox
            label="Segundo nombre"
            value={second_name.value}
            disabledMargin={false}
            onChange={second_name.onChange}
            error={second_name.error}
            containerStyles={{marginTop: 30}}
          />
          <InputBox
            label="Primer apellido"
            value={first_last_name.value}
            disabledMargin={false}
            onChange={first_last_name.onChange}
            error={first_last_name.error}
            containerStyles={{marginTop: 30}}
          />
          <InputBox
            label="Segundo apellido"
            value={second_last_name.value}
            disabledMargin={false}
            onChange={second_last_name.onChange}
            error={second_last_name.error}
            containerStyles={{marginTop: 30}}
          />

          <Box marginTop="20px">
            <Typography
              style={{
                fontFamily: 'App-semi-bold',
                fontSize: 12,
                marginTop: 24,
              }}
            >
              Tipo de documento
            </Typography>
            <SelectBox
              options={helpData?.document_type}
              outlined
              withMargin={false}
              value={documentType.value}
              selectKey="id"
              selectValue="name"
              selectText="value"
              onChange={documentType.onChange}
            />
          </Box>

          {(documentType.value == 'ps' || documentType.value == 'pep') ? (

            <InputBox
              disabledMargin={false}
              type="text"
              value={documentId.value}
              onChange={(e) => {
                documentId.onChange(e);
              }}
              label="Documento de identificación"
              containerStyles={{marginTop: 30}}
              error={documentId.error}
            />

          ) : (
            <InputBox
              disabledMargin={false}
              value={documentId.value}
              maxLength={11}
              minLenght={6}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  documentId.setValue('');
                }
                if (e.target.value.match(/[0-9]$/g)) {
                  documentId.onChange(e);
                }
              }}
              label="Documento de identificación"
              containerStyles={{marginTop: 30}}
              error={documentId.error}
            />
          )}


          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <KeyboardDatePicker
              style={{width: '100%', marginTop: 30}}
              value={format_date}
              label="Fecha de expedición"
              keyboardIcon={() => <> </>}
              placeholder="dd/mm/yyyy"
              format="dd/MM/yyyy"
              icon={<CalendarTodayIcon/>}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              onChange={(date) => setExpeditionDate(moment(date).add(1, 'days').format('YYYY-MM-DD'))}
              error={false}
              helperText={null}
            />
          </MuiPickersUtilsProvider>

        </>
      )}
      {accountType === 'Persona Jurídica' && (
        <>
          <Typography
            style={{fontFamily: 'App-semi-bold', fontSize: 12, marginTop: 24}}
          >
            Sector económico (CIIU)
          </Typography>

          <Box
            marginTop="8px"
            marginBottom="8px"
            display="flex"
            alignItems="center"
            height="40px"
          >
            <InputBox
              disabledMargin={true}
              value={ciiu.value}
              maxLength={4}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  ciiu.setValue('');
                }
                if (e.target.value.match(/[0-9]$/g)) {
                  ciiu.onChange(e);
                }
              }}
              // label="Documento de identificación"
              containerStyles={{marginTop: 30}}
              error={ciiu.error}
            />
            <BootstrapTooltip
              title={helpData?.help_text?.help_ciiu}
              open={openTooltip}
            >
              <HelpIcon
                fontSize="default"
                htmlColor="#00e39c"
                style={{marginLeft: 10, cursor: 'pointer', outline: 0}}
                onClick={() => setOpenTooltip(!openTooltip)}
              />
            </BootstrapTooltip>
          </Box>
          <a
            href={helpData?.invalid_ciiu_link}
            target="_blank"
            without
            rel="noreferrer"
          >
            <Box display="flex" alignItems="center" marginTop="20px">
              <InfoIcon
                fontSize="small"
                htmlColor="#00e39c"
                style={{marginRight: 5}}
              />
              <Typography
                color="textSecondary"
                style={{
                  fontSize: 12,
                  fontFamily: 'App-semi-bold',
                  color: '#00e39c',
                }}
              >
                Sectores económicos no permitidos (CIIU)
              </Typography>
            </Box>
          </a>
        </>
      )}

      <PhoneInputBox
        value={phoneNumber}
        onChange={setPhoneNumber}
        error={isSubmitting && !phoneNumber}
      />
      <InputBox
        label="Correo Electrónico"
        type='email'
        value={userEmail.value}
        onChange={userEmail.onChange}
        error={userEmail.error}
        disabledMargin={false}
      />
      <Box display="flex" alignItems="flex-start" marginTop="32px">
        <Checkbox
          checked={checked}
          onChange={() => setChecked(!checked)}
          disableRipple
          color="primary"
          inputProps={{'aria-label': 'primary checkbox'}}
          style={{marginRight: 12, padding: 0}}
        />
        <Typography variant="body1" style={{fontSize: 12, color: '#6F7782'}}>
          Acepto las políticas de{' '}
          <a href={helpData?.privacy_policy} target="_blank" rel="noreferrer">
            <Typography className={classes.text}>
              privacidad y tratamiento de datos personales.
            </Typography>
          </a>{' '}
        </Typography>
      </Box>
      <Box display="flex" alignItems="flex-start" marginTop="10px">
        <Checkbox
          checked={terms}
          onChange={() => setTerms(!terms)}
          disableRipple
          color="primary"
          inputProps={{'aria-label': 'primary checkbox'}}
          style={{marginRight: 12, padding: 0}}
        />
        <Typography variant="body1" style={{fontSize: 12, color: '#6F7782'}}>
          Acepto los{' '}
          <a href={helpData?.terms} target="_blank" rel="noreferrer">
            <Typography className={classes.text}>
              términos y condiciones
            </Typography>
          </a>{' '}de uso.
        </Typography>
      </Box>
      <ButtonApp
        text="Continuar"
        disabled={!checked || !terms}
        onClick={onSignupUser}
        marginTop={30}
        loading={loading}
      />

      <ModalApp open={open} handleClose={() => setOpen(false)} type="warning">
        <Typography style={{fontSize: 12, marginTop: 16}} align="center">
          Antes de continuar asegúrese de tener a mano los siguientes documentos
          que serán necesarios para completar la solicitud:
        </Typography>
        {accountType === 'Persona Jurídica' && (
          <Typography style={{fontSize: 12, marginTop: 20}} align="center">
            El proceso lo debe ejecutar el representante legal.
          </Typography>
        )}

        <Typography style={{fontSize: 12, marginTop: 30, marginBottom: 20}}>
          - Identificación / Cédula de Identidad
        </Typography>
        <Typography style={{fontSize: 12, marginBottom: 20}}>
          - Rut (Registro único tributario)
        </Typography>
        <Typography style={{fontSize: 12, marginBottom: 20}}>
          - {isClaro ? 'Número de deposito bajo monto' : 'Número de cuenta bancaria'}
        </Typography>

        <ButtonApp
          text="Entendido"
          onClick={() => setOpen(false)}
          marginTop={30}
        />
      </ModalApp>
    </Box>
  );
};

export default CreateAccount;
