import { Box, Typography } from '@material-ui/core';
import React from 'react';

const StepDescription = ({ title, value, label }) => (
  <Box style={{ marginBottom: 16 }}>
    {title && (
      <Typography
        style={{ fontSize: 16, fontFamily: 'App-bold', marginBottom: 12 }}
      >
        {title}
      </Typography>
    )}
    <Typography style={{ fontSize: 12, fontFamily: 'App-regular' }}>
      {value}
    </Typography>

      <Typography style={{ fontSize: 12, fontFamily: 'App-regular', paddingLeft: 24 }}>
          <div dangerouslySetInnerHTML={{ __html: label }}></div>
      </Typography>
  </Box>
);

export default StepDescription;
