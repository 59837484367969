import { appEndpointWithToken } from '../utils/axios';

const sendEmailOTP = async () => {
    try {
        const res = await appEndpointWithToken().post('/email-send-otp');
        return res;
    } catch (error) {
        throw error.response.data;
    }
};

export default sendEmailOTP;
