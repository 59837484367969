import { Box, makeStyles, Typography } from '@material-ui/core';

import React, { useContext, useState } from 'react';
import ButtonApp from '../../Components/ButtonApp';
import { signInWithEmailLink } from '../../firebase';
import { toast } from 'react-toastify';
import registerUser from '../../services/RegisterUser';
import getMe from '../../services/getMe';
import { Context } from '../../Context';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    container: {
        padding: 25,
        flexDirection: 'column',
        display: 'flex',
        minHeight: '100vh',
    },
    inputCode: {},
}));

const VerifyEmail = () => {
    const classes = useStyles();
    const [status, setStatus] = useState('idle');
    const { setAuth, setFormCompleted, onSignOut, setCheckCompletedStep, isClaro } =
        useContext(Context);
    const history = useHistory();
    let userData;
    let apiKey = (new URLSearchParams(window.location.search)).get("apiKey");
    userData = JSON.parse(localStorage.getItem('user-data'));

    const {
        phoneNumber,
        email,
        companyName,
        nit,
        profile,
        name,
        documentId,
        referral_code,
        ciiu,
        terms,
        isSignup,
        documentType,
    } = userData;

    const onSignInWithEmailLink = async () => {
        setStatus('pending');

        if((apiKey !== '' && apiKey != null)) {

            try {
                await signInWithEmailLink(
                    email,
                    window.location.href
                );

            } catch (error) {
                console.log(error);
            }

            if(isSignup) {
                setTimeout(() => {
                    let userUid = localStorage.getItem('_uid');

                    registerUser(
                        profile,
                        name,
                        companyName,
                        documentId,
                        nit,
                        ciiu,
                        null,
                        userUid,
                        phoneNumber.slice(2),
                        email,
                        terms,
                        'email',
                        referral_code,
                        documentType,
                        isClaro
                    )
                        .then((res) => {
                            toast.success(`El usuario se ha creado satisfactoriamente`, {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setStatus('resolved');
                            setAuth(true);

                            history.push('/document_id');
                            setCheckCompletedStep(false);
                        })
                        .catch((err) => {
                            const errorKeys = Object.keys(err?.errors);

                            let message = '';

                            for (const key of errorKeys) {
                                message += `⋅ ${key} - ${err?.errors[key]} 
                                `;
                            }
                            toast.error(message, {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            onSignOut();
                            setStatus('rejected');

                            history.push('/verify');
                        });
                }, 2000);
            } else {
                getMe()
                    .then((res) => {
                        const steps = res?.data?.steps;
                        const keys = Object.keys(steps);
                        setStatus('resolved');
                        setCheckCompletedStep(false);
                        for (const key of keys) {
                            if (steps[key]?.status?.name === 'incomplete') {
                                if (
                                    steps[key]?.title === 'Tu solicitud esta siendo procesada'
                                ) {
                                    history.push('/submitted');
                                    return;
                                }
                                history.push(`/${steps[key]?.name}`);
                                return;
                            } else if (
                                steps[keys[keys.length - 1]]?.status?.name === 'complete' ||
                                steps[key]?.title === 'Tu solicitud esta siendo procesada'
                            ) {
                                setFormCompleted(true);
                                history.push('/submitted');
                            }
                        }

                        setAuth(true);
                    })
                    .catch((err) => {
                        toast.error(err.message);
                    });
            }

        }

    }

    return (
        <Box className={classes.container}>
            <Box flexGrow={1}>

                <Typography style={{ fontFamily: 'App-bold', fontSize: 20 }} align="center">
                    Validación de la información
                </Typography>

                <Typography style={{ fontSize: 13, margin: '50px 0 50px 0' }} align="center">
                    Continúa con la validación de la información haciendo click en el siguiente botón
                </Typography>

                <ButtonApp
                    style={{ margin: '50px 0 0' }}
                    text="Validar información"
                    onClick={onSignInWithEmailLink}
                    loading={status === 'pending'}
                />

            </Box>
        </Box>
    );
};

export default VerifyEmail;
