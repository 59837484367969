import {Box, Typography} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import ButtonApp from '../../Components/ButtonApp';
import LoadingStep from '../../Components/LoadingStep';
import ProgressHeader from '../../Components/ProgressHeader';
import {getConfig} from '../../config/config';
import {setDefaultState} from '../../config/handlers';
import {Renderer} from '../../config/Renderer';
import {Context} from '../../Context';
import useGetHelpData from '../../hooks/useGetHelpData';
import useGetMe from '../../hooks/useGetMe';
import onSaveStep from '../../services/saveStep';

const BankAccount = () => {
  const {me, loadingMe} = useGetMe();
  const [oldState, setOldState] = useState({});
  const [state, setState] = useState({});
  const [loading, setLoading] = useState('idle');
  const history = useHistory();
  const helpData = useGetHelpData(
    me?.user?.profile,
    me?.user?.profile === 'Persona Juridica'
      ? me?.steps?.step_9?.name
      : me?.steps?.step_7?.name
  );
  const {setDesktopImg} = useContext(Context);

  useEffect(() => {
    if (helpData?.desktop_img) {
      setDesktopImg(helpData?.desktop_img);
    }
  }, [helpData?.desktop_img]);
  useEffect(() => {
    if (me) {
      if (
        me?.user?.profile === 'Persona Juridica' &&
        me?.steps?.step_9?.order === 9
      ) {
        setDefaultState(me?.steps?.step_9?.body, state, setState);
        return;
      }
      if (me?.steps?.step_7?.order === 7) {
        setDefaultState(me?.steps?.step_7?.body, state, setState);
      }
    }
  }, [me]);

  useEffect(() => {
    setOldState(state);
  }, [state]);

  const config = useMemo(() => {
    if (me) {
      const result = getConfig({
        state,
        setState,
        componentsFromServer:
          me?.user?.profile === 'Persona Juridica'
            ? me?.steps?.step_9?.body
            : me?.steps?.step_7?.body,
      });

      if (oldState['field_168'] !== state['field_168']) {
        if (state['field_168'] === 'otras') {
          result.forEach((el) => {
            if (el.id === 73) {
              el.disable = false;
              el.value = ''
              state['field_73'] = '';
            }
            if (el.id === 74) {
              el.label = 'Número de cuenta bancaria'
            }
          })
        } else {
          result.forEach((el) => {
            if (el.id === 73) {
              el.disable = true;
              const value = el.options.find((option) => option.id === 147)?.value;
              el.value = value;
              state['field_73'] = value;
            }
            if (el.id === 74) {
              el.label = 'Digita tú número de depósito de bajo monto'
            }
          })
        }
      }
      return result;
    }
  }, [state, me]);


  const saveStep = () => {
    setLoading('pending');

    onSaveStep({...state, view: 'bank_account'})
      .then((data) => {
        setLoading('resolved');

        me.user.profile === 'Persona Natural'
          ? history.push('/unrelated_activities')
          : history.push('/submitted')
      })
      .catch((err) => {
        const errorKeys = Object.keys(err.response?.data?.errors);

        const error = errorKeys.map((key, index) => (
          <Typography
            key={index}
            style={{fontSize: 12, fontFamily: 'App-regular'}}
          >{`⋅ ${key} - ${err.response?.data?.errors[key][0]}`}</Typography>
        ));

        if (!error) {
          toast.error(err);
        }

        toast.error(<Box>{error}</Box>, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading('rejected');
      });
  };

  if (loadingMe) {
    return <LoadingStep/>;
  }

  return (
    <Box
      padding="25px"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      style={{overflowY: 'auto'}}
    >
      <ProgressHeader
        title={
          me?.user?.profile === 'Persona Juridica'
            ? me?.steps?.step_9?.title
            : me?.steps?.step_7?.title
        }
        progress={
          me?.user?.profile === 'Persona Juridica'
            ? me?.steps?.step_9?.progress
            : me?.steps?.step_7?.progress
        }
        onBack={() =>
          me.user.profile === 'Persona Natural'
            ? history.push('/financial_information')
            : history.push('/unrelated_activities')
        }
        steps={me?.steps}
      />

      <Box marginTop="30px" flexGrow={1}>
        <Renderer config={config}/>
      </Box>
      <ButtonApp
        text="Siguiente"
        loading={loading === 'pending'}
        onClick={() => saveStep()}
      />
    </Box>
  );
};

export default BankAccount;