import { Box, Typography } from '@material-ui/core';
import React from 'react';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

const DocumentStatus = ({
  status = 'pending',
  statusText = 'Pendiente por Verificar',
  background,
  color,
}) => {
  const getColorByStatus = () => {
    switch (status) {
      case 'pending': {
        return {
          background: '#FF9D0119',
          color: '#FF9D00',
        };
      }

      default: {
        return {
          background: '#FF9D0119',
          color: '#FF9D00',
        };
      }
    }
  };

  return (
    <Box
      width="100%"
      height="31px"
      borderRadius="8px"
      padding="8px 16px"
      display="flex"
      alignItems="center"
      style={{ backgroundColor: background }}
    >
      <Typography style={{ fontSize: 12, fontFamily: 'App-font-medium' }}>
        Estado:
      </Typography>
      <Box display="flex" alignItems="center" marginLeft="5px">
        <QueryBuilderIcon
          htmlColor={color}
          style={{ fontSize: 12, marginRight: 3 }}
        />
        <Typography style={{ fontSize: 12, color: color }}>
          {statusText}
        </Typography>
      </Box>
    </Box>
  );
};

export default DocumentStatus;
