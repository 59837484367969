import { appEndpoint } from '../utils/axios';

const loginPhoneOtp = async (phone) => {
    const dataRegisterPhoneOtp = {
        phone
    }
    try {
        const res = await appEndpoint().post('/login-phone-otp', dataRegisterPhoneOtp);
        return res;
    } catch (error) {
        throw error.response.data;
    }
};

export default loginPhoneOtp;