import { appEndpointWithFiles, appEndpointWithToken } from '../utils/axios';

const onSaveStep = async (obj, hasFiles) => {
  try {
    if (hasFiles) {
      const { data } = await appEndpointWithFiles().post('/save-step', obj);
      return data;
    }
    const { data } = await appEndpointWithToken().post('/save-step', obj);
    return data;
  } catch (error) {
    throw error;
  }
};

export default onSaveStep;
