import { Box, Typography } from '@material-ui/core';
import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const AddMore = ({ title, order, onClick, componentToDuplicate }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ cursor: 'pointer' }}
      onClick={() => onClick(componentToDuplicate)}
    >
      <AddCircleIcon
        htmlColor="#00e39c"
        style={{ fontSize: 16, marginRight: 5 }}
      />
      <Typography
        style={{
          fontSize: 13,
          fontFamily: 'App-font-medium',
          color: '#00e39c',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default AddMore;
