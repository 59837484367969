import { appEndpoint } from '../utils/axios';

const registerEmailOtp = async (email) => {
    const dataRegisterEmailOtp = {
        email
    }
    try {
        const res = await appEndpoint().post('/register-email-otp', dataRegisterEmailOtp);
        return res;
    } catch (error) {
        throw error.response.data;
    }
};

export default registerEmailOtp;