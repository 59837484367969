import {appEndpointWithToken} from '../utils/axios';

const getMe = async () => {
  try {
    return await appEndpointWithToken().get('/me');
  } catch (error) {
    throw error.response.data;
  }
};

export default getMe;
