import {Box, Checkbox, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {Input} from "./InputBox";
import {handleChangeCheckbox} from "../config/handlers";

const InputCheckBox = ({ id, onChange, label, value }) => (

    <Box display="flex" alignItems="flex-start" marginTop="32px" marginBottom="32px" paddingLeft="24px">

        <Checkbox
            defaultChecked={value}
            onChange={onChange}
            id={id}
            color="primary"
            style={{ marginRight: 12, padding: 0 }}
        />

        <Typography variant="body1" style={{ fontSize: 12, color: '#6F7782' }}>
            {label}
        </Typography>

    </Box>
);

export default InputCheckBox;
