import React, { createContext, useState } from 'react';
import { signOut } from './firebase';
import useGetHelpData from './hooks/useGetHelpData';

export const Context = createContext();

const Provider = ({ children }) => {
  const helpData = useGetHelpData();
  const [auth, setAuth] = useState(false);
  const [desktopImg, setDesktopImg] = useState('');
  const [formCompleted, setFormCompleted] = useState(false);
  const [checkCompletedStep, setCheckCompletedStep] = useState(true);
  const [isClaro, setIsClaro] = useState(false);
  const [isHome, setIsHome] = useState(true);

  const value = {
    helpData,
    auth,
    desktopImg,
    formCompleted,
    checkCompletedStep,
    setAuth,
    setDesktopImg,
    setFormCompleted,
    setCheckCompletedStep,
    isClaro,
    setIsClaro,
    isHome,
    setIsHome,
    onSignOut: () => {
      signOut().then(() => {
        setFormCompleted(false);
        setAuth(false);
      });
    },
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
