import { Box, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';

import {
  InputBase,
  NativeSelect,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

let fieldAddress;


const BootstrapInputFillled = withStyles((theme, value) => ({
  root: {
    width: '100%',

    height: 40,
  },
  input: {
    borderRadius: 120,
    color: '#6F778280',
    position: 'relative',
    backgroundColor: '#f8f8f9',
    border: '1px solid #f8f8f9',
    fontSize: 13,
    height: 20,
    padding: '7px 26px 7px 18px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      'App-regular',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#f8f8f9',
      backgroundColor: '#f8f8f9',
    },
  },
}))(InputBase);

const BootstrapInputOutlined = withStyles((theme) => ({
  root: {
    width: '100%',
    height: 40,
    '& svg': {
      right: 10,
    },
  },
  input: {
    color: '#273240',
    borderRadius: 120,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #6F778280',
    fontSize: 13,
    height: 25,
    padding: '7px 26px 7px 18px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'App-regular',
      'App-font-medium',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 120,
      borderColor: '#6F778280',
      backgroundColor: 'transparent',
    },
    '&::placeholder': {
      color: '#6F778280',
    },
  },
}))(InputBase);


var InputAddressBox = ({
                   id,
                   value,
                   onChange,
                   options,
                   relatedData,
                   outlined = true,
                   selectKey,
                   selectValue = 'value',
                   selectText = 'text',
                   label,
                   withMargin = true,
                   type,
                   disable,
                   loading,
                   isVerified,
                   placeHolder,
                   inputStyles,
                   labelStyles,
                   capitalize,
                   uppercase,
                   containerStyles,
                   maxLength,
                   minLenght,
                   error,
                   suffix,
                   disabledMargin = true,
                 }) => {

  if(options.length > 0) {

    fieldAddress = <>
      <Wrapper
          style={
            disabledMargin
                ? {...containerStyles, marginBottom: '25px'}
                : {...containerStyles}
          }
      >
        <Typography
            style={{ fontFamily: 'App-semi-bold', fontSize: 12, marginBottom: 10 }}
        >
          {label}
        </Typography>

        <NativeSelect
            disabled={disable}
            selectMenu={{ background: 'blue' }}
            id={id}
            input={
              outlined ? (
                  <BootstrapInputOutlined value={value} />
              ) : (
                  <BootstrapInputFillled />
              )
            }
            onChange={onChange}
            value={value}
            IconComponent={ExpandMoreIcon}
            style={{
              textTransform: 'capitalize',
              marginBottom: withMargin ? 25 : 0,
            }}
            defaultValue={`Seleccione una opción.`}
        >
          <option style={{ color: '#6F778280' }} value="0">
            Seleccione una opción.
          </option>

          {typeof options?.[0] === 'string'
              ? options.map((option, index) => (
                  <option
                      value={option}
                      key={index}
                      style={{ textTransform: 'capitalize', color: '#273240' }}
                  >
                    {option}
                  </option>
              ))
              : options?.map((option, index) => (
                  <option
                      value={option[selectValue]}
                      key={selectKey ? option[selectKey] : index}
                      style={{ textTransform: 'capitalize', color: '#273240' }}
                  >
                    {option[selectText]}
                  </option>
              ))}

        </NativeSelect>
      </Wrapper>
    </>;
  } else {
    fieldAddress = <>
      <Wrapper
          style={
            disabledMargin
                ? {...containerStyles, marginBottom: '25px'}
                : {...containerStyles}
          }
      >

        <Input
            capitalize={capitalize}
            uppercase={uppercase}
            value={value || ''}
            onChange={onChange}
            id={id}
            minLenght={minLenght}
            maxLength={maxLength}
            placeholder={placeHolder}
            type={type}
            disabled={disable}
            style={inputStyles}
            autoComplete="off"
        />
        {label && (
            <Label htmlFor={id} style={labelStyles} value={value}>
              {label}
            </Label>
        )}
        <Typography
            style={{color: 'red', fontSize: 10, fontFamily: 'App-font-medium'}}
        >
          {error}
        </Typography>

        {suffix && (
            <Box
                display="flex"
                alignItems="center"
                position="absolute"
                top="11px"
                right="15px"
            >
              <Typography style={{color: '#27324080', fontSize: 14}}>
                {suffix}
              </Typography>
            </Box>
        )}


      </Wrapper>
    </>;
  }
  return ( fieldAddress );
};

export default InputAddressBox;



export const Input = styled.input`
  font-size: 14px;
  padding: 10px 15px 10px 15px;
  color: #273240;
  width: 100%;
  border: none;
  font-family: 'App-font-medium';
  border-bottom: 1px solid #6f778280;
  outline: none;
  margin: auto;
  ${(props) => props.capitalize && `text-transform: capitalize`};

  ${(props) => props.uppercase && `text-transform: uppercase`};
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #6f7782;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 21%;
  display: inline-block;
  margin: 0 5px;
  & input:focus + label {
    top: -15px;
  }
`;

export const Label = styled.label`
  color: #6f7782;
  font-size: 12px;
  font-family: 'App-regular';
  position: absolute;
  pointer-events: none;
  left: ${(props) => (props.isCurrency ? '12px' : '5px')};
  top: 10px;
  transition: 0.2s ease all;
  ${(props) => props.value && `top: -15px`};
`;
